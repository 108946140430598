import * as Yup from "yup";
const productlist = Yup.object().shape({
  name: Yup.string("Enter a Product Name")
    .required("Product Name is required")
    .max(100, "not more than 100"),
  comment: Yup.string("Enter a Comment").max(100, "not more than 100"),
  shortdescription: Yup.string("Enter a shortDesc").max(
    100,
    "not more than 100"
  ),
});
const productCatvalidation = Yup.object().shape({
  title: Yup.string("Enter a title")
    .required("title is required")
    .max(100, "not more than 100"),
  shortDesc: Yup.string("Enter a shortDesc").max(100, "not more than 100"),
  img: Yup.string().required("You need to provide a 360  deg Images"),
});
export { productCatvalidation, productlist };
