import React, { useEffect } from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Cards from "../ReuseComponent/cards";
import { getContact } from "../../store/action/contactaction";
import CsvDownloader from "react-csv-downloader";
import PlayForWorkRoundedIcon from "@material-ui/icons/PlayForWorkRounded";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: 48,
    marginLeft: 20,
    flexWrap: "wrap",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
const Contact = () => {
  const dispatch = useDispatch();

  const contactData = useSelector((state) => state.getContactReducer);
  useEffect(() => {
    dispatch(getContact());
  }, [dispatch]);

  const classes = useStyles();

  const columns = [
    {
      id: "fullName",
      displayName: "fullName",
    },
    {
      id: "email",
      displayName: "email",
    },
    { id: "countryCode", displayName: "countryCode" },
    { id: "phone", displayName: "phone" },
    { id: "location", displayName: "location" },
    { id: "product_name", displayName: "product_name" },
    { id: "message", displayName: "message" },
  ];

  const datas = contactData && contactData.ContactList;

  return (
    <Grid md={12} xs={12} sm={12} item>
      <Grid md={12} xs={12} sm={12} item container justify="space-between">
        <Typography
          style={{
            color: "#646464",
            fontSize: "24px",
            fontWeight: 700,
            margin: "24px 32px",
          }}
        >
          Contact Requests
        </Typography>

        <CsvDownloader
          filename="myfile"
          separator=";"
          wrapColumnChar="'"
          columns={columns}
          datas={datas}
          text="DOWNLOAD"
        >
          <Button
            style={{
              color: "#FD6A02",
              fontSize: "16px",
              fontWeight: 700,
              margin: "24px 32px",
              textTransform: "none",
            }}
          >
            <PlayForWorkRoundedIcon />
            Download CSV
          </Button>
        </CsvDownloader>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        spacing={4}
        className={classes.root}
      >
        {contactData.ContactList &&
          contactData.ContactList.map((obj, index) => {
            return (
              <Grid md={6} xs={12} sm={12} item>
                <Cards data={obj} />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
export default Contact;
