import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductAction } from "../../../store/action/productAction";

import AddIcon from "@material-ui/icons/Add";
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ProductUpdate from "../AddProductForm/AddProduct";
import ProductImageUpdate from "./imagesUpdateForm/imageUpdate";
const useStyles = makeStyles((theme) => ({
  title: {
    color: "#646464",
    fontWeight: 700,
    fontSize: 24,
    padding: 20,
  },
  subtitle: {
    color: "#030201",
    fontWeight: 700,
    fontSize: 20,
  },
  featuredImageGrid: {
    height: 160,

    padding: 20,
  },
  imageTitle: {
    color: "#646464",
    fontWeight: 700,
    fontSize: 14,
  },
  text: {
    color: "#646464",
    fontWeight: 700,
    fontSize: 14,
    padding: "0px 20px",
  },
  MuiAccordionroot: {
    background: "none",
    boxShadow: "none",
    "&.MuiAccordion-root:before": {
      height: 0,
    },
  },
  EditIcon: {
    margin: "8px 16px",
    color: "#000000",
    fontSize: 17,
  },
}));

const ProductDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const AllProduct = useSelector((state) => state.getProductReducer);

  const [pramsId, setpramsId] = useState(null);
  const [openFormText, setopenFormText] = useState(false);
  const [open, setopen] = useState();
  const [ImageUpdateopen, setImageUpdateopen] = useState();
  useEffect(() => {
    let paramsId = new URLSearchParams(window.location.search).get("id");
    let paramsCat = new URLSearchParams(window.location.search).get("product");
    setpramsId(paramsId);
    dispatch(getProductAction(paramsCat));
  }, [dispatch]);

  let data =
    AllProduct &&
    AllProduct.allProduct &&
    AllProduct.allProduct.filter((obj) => obj._id === pramsId);

  const handleOpen = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
    setImageUpdateopen(false);
  };
  const handleImageOpen = (val) => {
    if (val) {
      setopenFormText(true);
    }
    setImageUpdateopen(true);
  };
  let categorySting = new URLSearchParams(window.location.search).get(
    "category"
  );

  return (
    <Grid md={12} xs={12} sm={12} container item>
      {data &&
        data.map((obj, i) => (
          <Grid md={12} xs={12} sm={12} item container key={i}>
            <Grid
              md={9}
              xs={12}
              sm={12}
              item
              style={{
                background: "#EEEEEE",
                height: "100%",
                paddingBottom: 32,
              }}
            >
              <Typography className={classes.title}>
                Products | {categorySting} | {obj.title}
              </Typography>
              <Grid
                md={12}
                xs={12}
                sm={12}
                item
                container
                direction="row"
                style={{ padding: "3px 20px" }}
              >
                <Typography className={classes.subtitle}>Details</Typography>
                <IconButton onClick={() => handleOpen()} style={{ padding: 0 }}>
                  <EditIcon className={classes.EditIcon} />
                </IconButton>
              </Grid>
              <Grid
                md={12}
                xs={12}
                sm={12}
                item
                container
                justify="space-between"
              >
                <Grid md={4} item className={classes.featuredImageGrid}>
                  <Typography className={classes.imageTitle}>
                    Main image:
                  </Typography>
                  <img
                    src={obj.featuredImage}
                    alt="featuredImage"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "fill", background: "#C4C8CD" }}
                  />
                </Grid>
                <Grid md={1} item />
                <Grid md={4} item className={classes.featuredImageGrid}>
                  <Typography className={classes.imageTitle}>
                    Features Background:
                  </Typography>
                  <img
                    src={obj.backgroundImage}
                    alt="featuredImage"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "fill", background: "#C4C8CD" }}
                  />
                </Grid>
                <Grid md={1} item />
                <Grid md={12} xs={12} sm={12} item style={{ marginTop: 32 }}>
                  <Grid
                    md={12}
                    xs={12}
                    sm={12}
                    container
                    direction="row"
                    item
                    style={{ marginTop: 32 }}
                  >
                    <Grid md={3}>
                      <Typography className={classes.text}>Comment:</Typography>
                    </Grid>
                    <Grid md={7}>
                      <Typography className={classes.imageTitle}>
                        {obj.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    sm={12}
                    container
                    direction="row"
                    item
                    style={{ marginTop: 32 }}
                  >
                    <Grid md={3} item>
                      <Typography className={classes.text}>
                        Short Description:
                      </Typography>
                    </Grid>
                    <Grid md={7} item>
                      <Typography className={classes.imageTitle}>
                        {obj.shortDesc}
                      </Typography>
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      container
                      direction="row"
                      item
                      style={{ marginTop: 32 }}
                    >
                      <Grid md={3} item>
                        <Typography className={classes.text}>
                          Long Description:
                        </Typography>
                      </Grid>
                      <Grid md={7} item>
                        <Typography className={classes.imageTitle}>
                          {obj.longDesc}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      container
                      direction="row"
                      item
                      style={{ marginTop: 32 }}
                    >
                      <Grid md={3} item>
                        <Typography className={classes.text}>
                          Features:
                        </Typography>
                      </Grid>
                      <Grid md={7} item>
                        <Typography
                          className={classes.text}
                          dangerouslySetInnerHTML={{ __html: obj.features }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      container
                      direction="row"
                      item
                      style={{ marginTop: 32 }}
                    >
                      <Grid md={3} item>
                        <Typography
                          className={classes.text}
                          style={{ marginTop: 12 }}
                        >
                          Specifications:
                        </Typography>
                      </Grid>
                      <Grid md={7} item>
                        {obj.specifications
                          ? Array.isArray(obj.specifications) &&
                            obj.specifications.map((item) => (
                              <Accordion
                                classes={{
                                  root: classes.MuiAccordionroot,
                                }}
                              >
                                <AccordionSummary
                                  style={{
                                    padding: 0,
                                    minHeight: 0,
                                    margin: 0,
                                  }}
                                >
                                  <AddIcon
                                    fontSize="small"
                                    style={{
                                      padding: 0,
                                      color: "#030201",
                                    }}
                                  />
                                  <Typography
                                    className={classes.heading}
                                    style={{
                                      display: "contents",
                                      color: "#030201",
                                      fontSize: "14px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {item.title}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography className={classes.imageTitle}>
                                    <Typography
                                      className={classes.text}
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            ))
                          : ""}
                      </Grid>
                      <Grid
                        md={12}
                        xs={12}
                        sm={12}
                        container
                        direction="row"
                        item
                        style={{ marginTop: 32 }}
                      >
                        <Grid md={3} item>
                          <Typography className={classes.text}>
                            Datasheet:
                          </Typography>
                        </Grid>
                        <Grid md={7} item>
                          <a
                            href={obj.dataSheet}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Typography
                              style={{
                                fontWeight: 700,
                                color: "#FD6A02",
                                fontSize: 14,
                              }}
                            >
                              View Pdf
                            </Typography>
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid md={1} item />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={12}
              sm={12}
              item
              container
              justify="center"
              style={{ background: "#E8E8E8", height: "100%", paddingTop: 140 }}
            >
              <Grid md={8} item>
                <Grid
                  md={12}
                  item
                  container
                  justify="space-between"
                  style={{ display: "flex" }}
                >
                  <Typography className={classes.imageTitle}>
                    360º View:
                  </Typography>
                  <IconButton
                    onClick={() => handleImageOpen()}
                    style={{ padding: 0 }}
                  >
                    <EditIcon className={classes.EditIcon} />
                  </IconButton>
                </Grid>
                {Array.isArray(obj.imgUrl) &&
                  obj.imgUrl &&
                  obj.imgUrl.slice(0, 1).map((item) => (
                    <Grid style={{ height: 160 }}>
                      <img
                        src={item}
                        alt="360img"
                        width="100%"
                        height="100%"
                        style={{ background: "#C4C8CD" }}
                      />
                    </Grid>
                  ))}

                <Grid md={12} item style={{ marginTop: 32 }}>
                  <Grid
                    container
                    justify="space-between"
                    style={{ display: "flex" }}
                  >
                    <Typography className={classes.imageTitle}>
                      Additional Images:
                    </Typography>
                    <IconButton
                      onClick={() => handleImageOpen("Additional")}
                      style={{ padding: 0 }}
                    >
                      <EditIcon className={classes.EditIcon} />
                    </IconButton>
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    sm={12}
                    item
                    container
                    spacing={2}
                    justify="space-between"
                    style={{ display: "flex" }}
                  >
                    {obj.additionalImages
                      ? Array.isArray(obj.additionalImages) &&
                        obj.additionalImages &&
                        obj.additionalImages.map((item) => (
                          <Grid md={6} item style={{ height: 80 }}>
                            <img
                              src={item}
                              alt="360img"
                              width="100%"
                              height="100%"
                              style={{ background: "#C4C8CD" }}
                            />
                          </Grid>
                        ))
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ProductUpdate
              open={open}
              onClose={handleClose}
              ProductData={obj}
            />
            <ProductImageUpdate
              open={ImageUpdateopen}
              onClose={handleClose}
              ProductData={obj}
              openFormText={openFormText}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default ProductDetail;
