import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Toolbar from "./Toolbar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadUpdateData from "./AddDownloadData";
import { deleteDownload } from "../../store/action/donwloadaction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const DownloadCard = ({ className, downloadData, params, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const deleteDownloadResponse = useSelector((state) => state.deleteDownload);

  const handleDelte = (id) => {
    dispatch(deleteDownload(id));

    window.location.reload();
  };

  const [open, setopen] = useState(false);
  const [data, setdata] = useState();
  const handleOpen = (data) => {
    setdata(data);
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  return (
    <Grid md={12} sm={12} xs={12} item>
      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        direction="row"
        justify="space-between"
        style={{ marginTop: 32 }}
      >
        <Grid md={6} xs={6} sm={6} item>
          <Typography
            style={{
              fontSize: 24,
              color: "#646464",
              fontWeight: 700,
            }}
          >
            {params}
          </Typography>
        </Grid>
        <Grid md={6} xs={6} sm={6} item>
          <Toolbar />
        </Grid>
      </Grid>

      <Grid md={12} xs={12} sm={12} item>
        <Box mt={3}>
          <Grid container spacing={3}>
            {downloadData &&
              downloadData.map((item) => (
                <Grid item key={item.id} lg={4} md={6} xs={12}>
                  <Card className={clsx(classes.root, className)} {...rest}>
                    <Box p={2}>
                      <Grid
                        md={12}
                        item
                        style={{ display: "flex", marginTop: 12 }}
                      >
                        <Grid md={5} item>
                          <Typography className={classes.text}>
                            <span className={classes.boldText}> Title: </span>
                          </Typography>
                        </Grid>
                        <Grid md={7} item>
                          <Typography className={classes.text}>
                            {item ? item.title : null}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid md={3}>
                        {item.docUrl ? (
                          <Typography className={classes.text}>
                            <a
                              href={item.docUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span
                                style={{
                                  color: "#FD6A02",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                VIEW FILE
                              </span>
                            </a>
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid
                        md={12}
                        xs={12}
                        sm={12}
                        item
                        container
                        justify="space-between"
                        style={{ marginTop: 8 }}
                      >
                        <Box style={{ marginRight: 20 }}>
                          <IconButton
                            onClick={() => handleOpen(item)}
                            style={{ padding: 0 }}
                          >
                            <EditIcon
                              fontSize="small"
                              style={{ color: "#000000" }}
                            />
                          </IconButton>
                        </Box>
                        <Box>
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => handleDelte(item ? item._id : null)}
                          >
                            <DeleteIcon
                              fontSize="small"
                              style={{ color: "#000000" }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </Grid>
          <DownloadUpdateData open={open} onClose={handleClose} Data={data} />
        </Box>
      </Grid>
    </Grid>
  );
};

DownloadCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
};

export default DownloadCard;
