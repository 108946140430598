import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";
export const addProductCat = (req) => {
  let Product_Cat_data = req.data;
  let data = new FormData();
  data.append("title", Product_Cat_data.title);
  data.append("shortDesc", Product_Cat_data.shortDesc);
  data.append("imgUrl", Product_Cat_data.imgUrl);
  data.append("iconImage", Product_Cat_data.iconImage);
  data.append("subCategory", JSON.stringify(Product_Cat_data.subCategory));
  return axios
    .post(`${REACT_APP_API_URL}/productCategory/addproductCat`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getProductCat = (request) => {
  return axios
    .get(`${REACT_APP_API_URL}/productCategory/getAllProductCat`, request.data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProductCatService = (req) => {
  return axios
    .delete(
      `${REACT_APP_API_URL}/productCategory/deleteProductCat?id=${req.data}`
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProductCatservice = (req) => {
  let Product_Cat_data = req.data;
  let data = new FormData();
  data.append("title", Product_Cat_data.title);
  data.append("shortDesc", Product_Cat_data.shortDesc);
  data.append("imgUrl", Product_Cat_data.imgUrl);
  data.append("iconImage", Product_Cat_data.iconImage);
  data.append("subCategory", JSON.stringify(Product_Cat_data.subCategory));
  return axios
    .post(
      `${REACT_APP_API_URL}/productCategory/updateproductCat?id=${req.data.id}`,
      data
    )

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
