import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// import { Link } from "react-router-dom";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Form from "./NewsEvents";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleclose = () => {
    setOpen(false);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          style={{ backgroundColor: "#FD6A02" }}
          onClick={handleOpen}
        >
          {/* <Link
            to="/app/News/add"
            style={{
              backgroundColor: "#FD6A02",
              padding: 10,
              display: "flex",
              borderRadius: 4,
            }}
          > */}
          <AddOutlinedIcon
            style={{
              backgroundColor: "#fff",
              color: "#FD6A02",
              fontSize: 16,
              marginRight: 16,
              marginTop: 4,
              borderRadius: 2,
            }}
          />

          <Typography style={{ color: "#fff", fontWeight: 700 }}>
            Add New
          </Typography>
          {/* </Link> */}
        </Button>
      </Box>
      <Form open={Open} onClose={handleclose} />
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
