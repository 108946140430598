import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, Typography, makeStyles, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Form from "../AddProductForm/AddProduct";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleclose = () => {
    setOpen(false);
  };
  return (
    <Grid
      md={12}
      sm={12}
      xs={12}
      item
      className={clsx(classes.root, className)}
      {...rest}
      container
      justify="flex-end"
    >
      {/* <Box display="flex" justifyContent="flex-end"> */}
      <Button
        variant="contained"
        style={{ backgroundColor: "#FD6A02" }}
        onClick={handleOpen}
      >
        <AddOutlinedIcon
          style={{
            backgroundColor: "#fff",
            color: "#FD6A02",
            fontSize: 16,
            marginRight: 16,
            marginTop: 4,
            borderRadius: 2,
          }}
        />

        <Typography style={{ color: "#fff", fontWeight: 700 }}>
          Add New
        </Typography>
      </Button>
      {/* </Box> */}
      <Form open={Open} onClose={handleclose} />
    </Grid>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
