import React from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";

const Navlinks = [
  {
    title: "All Vacancy",
    url: "/app/careers/vacancy",
  },
  {
    title: "All Applications",
    url: "/app/careers/applications",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: 48,
    textAlign: "center",
    // marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  card: {
    cursor: "pointer",
    marginRight: 40,
    minHeight: 200,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
    },
  },
}));
const Careers = () => {
  const classes = useStyles();
  const onClickHandler = (url) => {
    return window.location.replace(`${url}`);
  };
  return (
    <Grid md={12} xs={12} sm={12} item className={classes.root}>
      {Navlinks.map((obj, index) => {
        return (
          <Grid md={6} xs={12} sm={12} item>
            <Box
              display="flex"
              bgcolor="white"
              alignItems="center"
              justifyContent="center"
              boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
              fontSize="h6.fontSize"
              borderRadius="8px"
              id="aboutNavCard"
              className={classes.card}
              key={index}
              onClick={() => onClickHandler(obj.url)}
            >
              {obj.title}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Careers;
