import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";
export const addDownloadService = (req) => {
  let downloadData = req.data;

  let data = new FormData();
  data.append("title", downloadData.title);
  data.append("docUrl", downloadData.docUrl);
  data.append("type", downloadData.type);

  return axios
    .post(`${REACT_APP_API_URL}/downloads/add`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getDownloadService = (req) => {
  return axios
    .get(`${REACT_APP_API_URL}/downloads/get?type=${req.data}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteDownloadService = (req) => {
  return axios
    .delete(`${REACT_APP_API_URL}/downloads/delete?id=${req.data}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateDownloadService = (req) => {
  let downloadData = req.data;

  let data = new FormData();
  data.append("title", downloadData.title);
  data.append("docUrl", downloadData.docUrl);
  data.append("type", downloadData.type);

  return axios
    .post(`${REACT_APP_API_URL}/downloads/update?id=${req.data.id}`, data)

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
