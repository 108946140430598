import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  DialogContent,
} from "@material-ui/core";
import Form from "./form";
import {
  AddProductAction,
  UpdateProductAction,
  DeleteProductImageAction,
} from "../../../store/action/productAction";
import { getProductCat } from "../../../store/action/productcategory";
import { productlist } from "./validation";
const AddVacancy = (props) => {
  const [ImageFile, setImageFile] = useState([]);
  const [backgroundIamge, setbackgroundIamge] = useState();
  const [addidtionalIamge, setaddidtionalIamge] = useState();
  const [dataSheet, setdataSheet] = useState();
  const [featureImage, setfeatureImage] = useState(null);
  const dispatch = useDispatch();

  const update_product = useSelector((state) => state.UpdateProductReducer);

  const add_product = useSelector((state) => state.addProductReducer);

  const AllProductCat = useSelector((state) => state.getProductCat);
  let product_cat =
    AllProductCat && AllProductCat.allProductCat && AllProductCat.allProductCat;
  const { close, onClose, open, ProductData } = props;
  const handleFile = (url) => {
    setImageFile(url);
  };
  const handleBackgroundImage = (url) => {
    setbackgroundIamge(url);
  };
  const AdditionalImages = (url) => {
    setaddidtionalIamge(url);
  };
  const handleDataSheetFIle = (url) => {
    setdataSheet(url);
  };
  const upload_FeaturedImage = (url) => {
    setfeatureImage(url);
  };
  const [loader, setloader] = useState(false);
  const ShowLoader = () => {
    setloader(true);
  };
  let filterProductCat = product_cat
    ?.filter((item) => item._id === ProductData?.productCategory)
    .map((obj) => obj.title);

  const initialval = {
    name:
      ProductData && ProductData.title ? ProductData && ProductData.title : "",
    img:
      ProductData && ProductData.imgUrl
        ? ProductData && ProductData.imgUrl
        : "",
    productCategory: filterProductCat ? [...filterProductCat] : "",
    subCategory:
      ProductData && ProductData.productSubCategory
        ? ProductData && ProductData.productSubCategory
        : "",
    comment:
      ProductData && ProductData.comment
        ? ProductData && ProductData.comment
        : "",
    shortdescription:
      ProductData && ProductData.shortDesc
        ? ProductData && ProductData.shortDesc
        : "",
    longDescription:
      ProductData && ProductData.longDesc
        ? ProductData && ProductData.longDesc
        : "",
    Features:
      ProductData && ProductData.features
        ? ProductData && ProductData.features
        : "",
    backgroundiamge:
      ProductData && ProductData.backgroundImage
        ? ProductData && ProductData.backgroundImage
        : "",
    additionalImages:
      ProductData && ProductData.additionalImages
        ? ProductData && ProductData.additionalImages
        : "",
    datasheet_file:
      ProductData && ProductData.dataSheet
        ? ProductData && ProductData.dataSheet
        : "",
    specifications:
      ProductData && ProductData.specifications
        ? ProductData && ProductData.specifications
        : "",
    featuredImage:
      ProductData && ProductData.featuredImage
        ? ProductData && ProductData.featuredImage
        : "",
  };

  const handleDlete_360_image = (url) => {
    dispatch(
      DeleteProductImageAction({
        id: ProductData && ProductData._id,
        imgUrl: url,
      })
    );
    window.location.reload();
  };

  const handleSubmit = (val) => {
    let filterProductCat = product_cat
      ?.filter((item) => item.title === val.productCategory)
      .map((obj) => obj._id);

    ShowLoader();
    if (!ProductData) {
      dispatch(
        AddProductAction({
          name: val.name,
          img: ImageFile,
          productCategory: [...filterProductCat],
          subCategory: val.subCategory,
          comment: val.comment,
          shortdescription: val.shortdescription,
          longDescription: val.longDescription,
          featuredImage: !featureImage
            ? ImageFile && ImageFile[0]
            : featureImage,
          Features: val.Features,
          backgroundiamge: backgroundIamge,
          additionalImages: addidtionalIamge,
          title: val.title,
          description: val.description,
          datasheet: dataSheet,
          specifications: val.specifications,
        })
      );
    }

    if (ProductData) {
      dispatch(
        UpdateProductAction({
          id: ProductData && ProductData._id,
          name: val.name ? val.name : ProductData && ProductData.title,
          img: ImageFile ? ImageFile : ProductData && ProductData.imgUrl,
          productCategory:
            filterProductCat?.length > 0
              ? filterProductCat?.[0]
              : ProductData && ProductData.productCategory,
          subCategory: val.subCategory
            ? val.subCategory
            : ProductData && ProductData.productSubCategory,
          featuredImage: featureImage,
          comment: val.comment
            ? val.comment
            : ProductData && ProductData.comment,
          shortdescription: val.shortdescription
            ? val.shortdescription
            : ProductData && ProductData.shortDesc,
          longDescription: val.longDescription
            ? val.longDescription
            : ProductData && ProductData.longDesc,
          Features: val.Features
            ? val.Features
            : ProductData && ProductData.features,
          backgroundiamge: backgroundIamge,
          additionalImages: addidtionalIamge,
          datasheet: dataSheet,
          specifications: val.specifications
            ? val.specifications
            : ProductData && ProductData.specifications,
        })
      );
    }
  };

  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getProductCat());
  }, [dispatch]);

  let productCategory = [
    ...new Set(
      product_cat?.map((item) => {
        return item.title;
      })
    ),
  ];
  let subCatArr = [];
  product_cat?.map((item) => {
    if (Array.isArray(item.subCategory)) {
      item.subCategory &&
        item.subCategory.map((obj) => {
          subCatArr.push(obj);
        });
    }
  });
  let productSubCategory = [
    ...new Set(
      subCatArr &&
        subCatArr.map((item) => {
          return item;
        })
    ),
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogContent style={{ padding: "32px 32px 50px 32px" }}>
        {add_product.success || update_product.success ? null : (
          <Typography style={{ margin: 20 }}>
            All <span style={{ color: "tomato" }}>*</span> fields are required
          </Typography>
        )}
        {add_product.success || update_product.success ? (
          <Grid
            container
            justify="center"
            style={{ padding: 100, minWidth: 900, display: "grid" }}
          >
            <Typography style={{ textAlign: "center" }}>
              Product {update_product.success ? "update" : "Add"} Successfully
            </Typography>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleClose}
              style={{
                background: "#FD6A02",
                textTransform: "none",
                marginTop: 24,
              }}
            >
              <Typography style={{ color: "#fff" }}>Close</Typography>
            </Button>
          </Grid>
        ) : (
          <Formik
            initialValues={initialval}
            close={close}
            onSubmit={handleSubmit}
            validationSchema={productlist}
          >
            {(props) => (
              <Form
                {...props}
                ImageFile={(url) => handleFile(url)}
                handleBackgroundImage={(url) => handleBackgroundImage(url)}
                handleAdditionalImage={(url) => AdditionalImages(url)}
                handleDataSheetFIle={(url) => handleDataSheetFIle(url)}
                handle_upload_FeaturedImage={(url) => upload_FeaturedImage(url)}
                handleDeletImage={(imageurl) => handleDlete_360_image(imageurl)}
                response={add_product}
                loader={loader}
                close={onClose}
                allProductCategory={productCategory}
                allSubCategory={productSubCategory}
              />
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddVacancy;
