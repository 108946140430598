import * as types from "../action.type";
export const getAllVacancyReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GETVACANCY_SUCCESS:
      return response;
    case types.GETVACANCY_ERROR:
      return state;
    default:
      return state;
  }
};

// OPEN VACANCY
export const addVacancyReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.ADD_VACANCY_SUCCESS:
      return response;
    case types.ADD_VACANCY_ERROR:
      return state;
    default:
      return state;
  }
};
// update vacancy
export const updateVacancyReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.UPDATE_VACANCY_SUCCESS:
      return response;
    case types.UPDATE_VACANCY_ERROR:
      return state;
    default:
      return state;
  }
};
// delete vacancy
export const deleteVacancyReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.DELETE_VACANCY_SUCCESS:
      return response;
    case types.DELETE_VACANCY_ERROR:
      return state;
    default:
      return state;
  }
};
// all applications
export const getAllApplication = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GET_APLICATION_SUCCESS:
      return response;
    case types.GET_APLICATION_ERROR:
      return state;
    default:
      return state;
  }
};
