import * as types from "../action.type";
// ADD NEWS
export const addNewsReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.ADD_NEWS_EVENTS_SUCCESS:
      return response;
    case types.ADD_NEWS_EVENTS_ERROR:
      return state;
    default:
      return state;
  }
};
// GET NEWS
export const getNewsReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GET_NEWS_EVENTS_SUCCESS:
      return response;
    case types.GET_NEWS_EVENTS_ERROR:
      return state;
    default:
      return state;
  }
};
// UPDATE NEWS
export const updateNewsReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.UPDATE_NEWS_EVENTS_SUCCESS:
      return response;
    case types.UPDATE_NEWS_EVENTS_ERROR:
      return state;
    default:
      return state;
  }
};

// delete NEWS
export const deleteNewsReducers = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.DELETE_NEWS_EVENTS_SUCCESS:
      return response;
    case types.DELETE_NEWS_EVENTS_ERROR:
      return state;
    default:
      return state;
  }
};
