import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Switch,
  Grid,
  withStyles,
  IconButton,
} from "@material-ui/core";
import AddVacancyModal from "./AddVacancyModel";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { updateVacancy } from "../../../store/action/careeraction";
const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "left",
  },
  boldText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  card: {
    cursor: "pointer",
    marginRight: 40,
    padding: 20,
    display: "block",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
    },
  },
  BottomGrid: {
    position: "relative",
    top: "40px",
    [theme.breakpoints.down("sm")]: {
      top: "10px",
    },
  },
  button: {
    textTransform: "none",
    color: "#3f51b5",
  },
}));
const Contact = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const [open, setopen] = useState(false);
  const classes = useStyles();
  // const update = useSelector((state) => state.updateVacancyReducer);
  const handleDelete = (id) => {
    props.handleDelete(id);
  };
  const handleUpdate = (data) => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };
  const [state, setState] = React.useState(
    data.isActive ? data.isActive : true
  );

  const handleSwitch = (event) => {
    event.persist();
    setState(event.target.checked);
    dispatch(
      updateVacancy({
        id: data._id,
        isActive: event.target.checked ? event.target.checked : false,
        Jobtitle: data.Jobtitle,
        shortDescription: data.shortDescription,
        country: data.country,
        city: data.city,
        JobDesc: data.JobDesc,
      })
    );
    window.location.reload();
  };
  const PurpleSwitch = withStyles({
    switchBase: {
      color: "#61686C",
      marginTop: -8,
      "&$checked": {
        color: "#FD6A02",
        "& + $track": {
          opacity: 1,
          backgroundColor: "rgba(253, 106, 2, 0.25)",
        },
      },
    },
    checked: {
      marginTop: -8,
    },

    track: {
      marginTop: -8,
      backgroundColor: "#8C9398",
      opacity: 1,
    },
  })(Switch);
  return (
    <Box
      bgcolor="white"
      alignItems="center"
      justifyContent="center"
      boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
      fontSize="h6.fontSize"
      borderRadius="8px"
      marginBottom="30px"
      className={classes.card}
      key={data.fullName}
    >
      <Grid md={12} xs={12} sm={12} item style={{ display: "flex" }}>
        <Grid md={6} xs={6} sm={6} item container justify="flex-start">
          <Typography className={classes.text}>{data.Jobtitle}</Typography>
        </Grid>
        <Grid md={6} xs={6} sm={6} item container justify="flex-end">
          <span style={{ display: "flex" }}>
            <Typography>{data.isActive ? "Active" : "InActive"}</Typography>
            <PurpleSwitch
              onChange={handleSwitch}
              checked={data.isActive ? data.isActive : false}
            />
          </span>

          <Box style={{ marginRight: 20 }}>
            <IconButton
              onClick={() => handleUpdate(data)}
              style={{ padding: 0 }}
            >
              <EditIcon fontSize="small" style={{ color: "#000000" }} />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => handleDelete(data._id)}
            >
              <DeleteIcon fontSize="small" style={{ color: "#000000" }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {data.city || data.country ? (
        <Typography
          className={classes.text}
          style={{ marginTop: 8 }}
        >{`${data.city},${data.country}`}</Typography>
      ) : null}
      <Typography className={classes.text} style={{ marginTop: 8 }}>
        {data.shortDescription}
      </Typography>
      {data.JobDesc ? (
        <Typography className={classes.text} style={{ marginTop: 16 }}>
          <span className={classes.boldText}> Job Description: </span>{" "}
          <a href={data.JobDesc} target="_blank" rel="noreferrer">
            <span
              style={{ color: "#FD6A02", fontSize: 16, fontWeight: "bold" }}
            >
              {" "}
              VIEW PDF{" "}
            </span>
          </a>
        </Typography>
      ) : null}
      <AddVacancyModal
        open={open}
        onClose={handleClose}
        Update_Previous_Data={data}
        isActive={state}
      />
    </Box>
  );
};
export default Contact;
