import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "./index.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavItem from "./NavItem";
import { useDispatch, useSelector } from "react-redux";
import { getProductCat } from "../../../store/action/productcategory";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },

  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  paper: {
    top: 0,
    height: 0,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }, props) => {
  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const AllProductCat = useSelector((state) => state.getProductCat);

  useEffect(() => {
    dispatch(getProductCat());
  }, [dispatch]);

  const items = [
    {
      href: "/app/products",
      title: "Product Category",
    },
    {
      title: "Product ",
      href: "/app/Allproducts",
      accordian: true,
    },

    {
      title: "Job Posting",
      href: "/app/careers/vacancy ",
    },
    {
      title: "Job Applications",
      href: "/app/careers/applications ",
    },

    {
      title: "Contact Requests",
      href: "/app/contact ",
    },
    {
      title: "News & Events",
      href: "/app/News ",
    },
    {
      title: "Downloads",
      href: "/app/downloads ",
      accordian: true,
    },
  ];
  let unique = [
    ...new Set(
      AllProductCat &&
        AllProductCat.allProductCat &&
        AllProductCat.allProductCat.map((item) => {
          return item;
        })
    ),
  ];

  let download = [
    { title: "Documentation", href: "documentation" },
    { title: "Corporate Reference", href: "corporateReference" },
  ];
  const handleCLick = (obj) => {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("product", obj);

    window.history.replaceState(
      null,
      null,
      "/app/Allproducts?" + queryParams.toString()
    );
    window.location.reload();
  };

  const handleDownload = (obj) => {
    var queryParams = new URLSearchParams(window.location.search);

    queryParams.set("download", obj);

    window.history.replaceState(
      null,
      null,
      "/app/downloads?" + queryParams.toString()
    );
    window.location.reload();
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <List>
          {items.map((item) =>
            item.accordian === true ? (
              <Accordion
                // onChange={handleChange("panel1")}
                style={{
                  boxShadow: "none",
                }}
                classes={{
                  root: classes.MuiAccordionroot,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{
                    padding: 0,
                  }}
                >
                  <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                  />
                </AccordionSummary>

                {item.href === "/app/Allproducts" ? (
                  <AccordionDetails style={{ display: "block" }}>
                    {unique.map((obj) => (
                      <Button
                        style={{
                          textTransform: "none",
                          display: "block",
                          width: "100%",
                          textAlign: "left",
                        }}
                        onClick={() => handleCLick(obj._id)}
                      >
                        <Typography style={{ color: "#546E7A" }}>
                          {obj.title}
                        </Typography>
                      </Button>
                    ))}
                  </AccordionDetails>
                ) : (
                  <AccordionDetails style={{ display: "block" }}>
                    {download.map((obj) => (
                      <Button
                        style={{
                          textTransform: "none",
                          display: "block",
                          width: "100%",
                          textAlign: "left",
                        }}
                        onClick={() => handleDownload(obj.href)}
                      >
                        <Typography style={{ color: "#546E7A" }}>
                          {obj.title}
                        </Typography>
                      </Button>
                    ))}
                  </AccordionDetails>
                )}
              </Accordion>
            ) : (
              <NavItem href={item.href} key={item.title} title={item.title} />
            )
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
