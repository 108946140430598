import { combineReducers } from "redux";
import {
  SignUpReducer,
  LoginReducer,
  authCheckReducer,
  updatePassword,
  logout,
} from "./signUpreducer";
import { getContactReducer } from "./contactReducer";
import {
  getAllVacancyReducer,
  addVacancyReducer,
  getAllApplication,
  updateVacancyReducer,
  deleteVacancyReducer,
} from "./careerReducer";
import {
  addNewsReducer,
  getNewsReducer,
  deleteNewsReducers,
  updateNewsReducer,
} from "./news_eventsReducer";
import {
  addProductCat,
  getProductCat,
  deleteProductCat,
  updateProductCat,
} from "./productCategoryReducer";

import {
  getProductReducer,
  addProductReducer,
  UpdateProductReducer,
  DeleteProductReducer,
  DeleteProductImageReducer,
  UpdateProductImageReducer,
} from "./productReducer";
import {
  addDownload,
  getDownload,
  updateDownload,
  deleteDownload,
} from "./downloadReducer";
const rootReducer = combineReducers({
  SignUpReducer,
  LoginReducer,
  authCheckReducer,
  updatePassword,
  addVacancyReducer,
  getAllVacancyReducer,
  updateVacancyReducer,
  deleteVacancyReducer,
  getAllApplication,
  getContactReducer,
  getNewsReducer,
  addNewsReducer,
  deleteNewsReducers,
  updateNewsReducer,
  addProductCat,
  getProductCat,
  deleteProductCat,
  updateProductCat,
  addProductReducer,
  UpdateProductReducer,
  DeleteProductReducer,
  DeleteProductImageReducer,
  getProductReducer,
  UpdateProductImageReducer,
  logout,
  addDownload,
  getDownload,
  updateDownload,
  deleteDownload,
});

export default rootReducer;
