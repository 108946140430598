import * as types from "../action.type";
export const getContactReducer = (state = {}, action) => {
  const response = action.response;

  switch (action.type) {
    case types.GETCONTACT_SUCCESS:
      return response;
    case types.GETCONTACT_ERROR:
      return state;
    default:
      return state;
  }
};
