import React from "react";

function about() {
  return (
    <div>
      <h1>about</h1>
    </div>
  );
}

export default about;
