import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  DialogContent,
} from "@material-ui/core";
import Form from "./form";
import {
  DeleteProductImageAction,
  UpdateProductImageAction,
} from "../../../../store/action/productAction";

const AddVacancy = (props) => {
  const [ImageFile, setImageFile] = useState([]);
  const { close, onClose, open, ProductData } = props;
  const [addidtionalIamge, setaddidtionalIamge] = useState();
  const [prevImage, setprevImage] = useState();
  const dispatch = useDispatch();

  const update_product_image = useSelector(
    (state) => state.UpdateProductImageReducer
  );
  const initialval = {
    img:
      ProductData && ProductData.imgUrl
        ? ProductData && ProductData.imgUrl
        : "",

    additionalImages:
      ProductData && ProductData.additionalImages
        ? ProductData && ProductData.additionalImages
        : "",
    AdditionForm: props.openFormText ? true : false,
  };
  const handleFile = (url) => {
    setImageFile(url);
  };
  const previousImageUpdateUrl = (url) => {
    setprevImage(url);
  };

  const AdditionalImages = (url) => {
    setaddidtionalIamge(url);
  };

  const [loader, setloader] = useState(false);
  const ShowLoader = () => {
    setloader(true);
  };

  const handleDlete_360_image = (url) => {
    dispatch(
      DeleteProductImageAction({
        id: ProductData && ProductData._id,
        imgUrl: url,
      })
    );
    window.location.reload();
  };
  const handleAdditionalImagedelete = (url) => {
    dispatch(
      DeleteProductImageAction({
        id: ProductData && ProductData._id,
        additionalImage: url,
      })
    );
    window.location.reload();
  };
  const handleSubmit = (val) => {
    ShowLoader();
    if (ImageFile) {
      dispatch(
        UpdateProductImageAction({
          id: ProductData && ProductData._id,
          img: ImageFile ? ImageFile : ProductData && ProductData.imgUrl,
          image: prevImage ? prevImage : null,
          additionalImages: addidtionalIamge,
        })
      );
    }
  };
  const handleClose = () => {
    onClose();
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogContent style={{ padding: "32px 32px 50px 32px" }}>
        {update_product_image.success ? null : (
          <Typography style={{ margin: 20 }}>
            All <span style={{ color: "tomato" }}>*</span> fields are required
          </Typography>
        )}
        {update_product_image.success ? (
          <Grid
            container
            justify="center"
            style={{ padding: 100, minWidth: 900, display: "grid" }}
          >
            <Typography style={{ textAlign: "center" }}>
              Product {update_product_image.success ? "update" : ""}{" "}
              Successfully
            </Typography>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleClose}
              style={{
                background: "#FD6A02",
                textTransform: "none",
                marginTop: 24,
              }}
            >
              <Typography style={{ color: "#fff" }}>Close</Typography>
            </Button>
          </Grid>
        ) : (
          <Formik
            initialValues={initialval}
            close={close}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form
                {...props}
                ImageFile={(url) => handleFile(url)}
                previousImageUpdateUrl={(url) => previousImageUpdateUrl(url)}
                handleAdditionalImage={(url) => AdditionalImages(url)}
                handleDeletImage={(imageurl) => handleDlete_360_image(imageurl)}
                handleAdditionalImagedelete={(url) =>
                  handleAdditionalImagedelete(url)
                }
                loader={loader}
                close={onClose}
              />
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddVacancy;
