import { put, call } from "redux-saga/effects";
import {
  addDownloadService,
  getDownloadService,
  updateDownloadService,
  deleteDownloadService,
} from "../service/download.service";
import {
  addDownloadSucess,
  getDownloadSuccess,
  updateDownloadSucess,
  deleteDownloadSucess,
} from "../action/donwloadaction.js";
import * as types from "../action.type";
export function* addDownloadSaga(data) {
  const response = yield call(addDownloadService, data);
  if (response && !response.error) {
    yield put(addDownloadSucess(response.data));
  } else {
    yield put({ type: types.ADDDOWNLOAD_ERROR, response });
  }
}
export function* getDownloadSaga(data) {
  const response = yield call(getDownloadService, data);

  if (response && !response.error) {
    yield put(getDownloadSuccess(response.data));
  } else {
    yield put({ type: types.GETDOWNLOAD_ERROR, response });
  }
}
// update

export function* updateDownloadSaga(data) {
  const response = yield call(updateDownloadService, data);
  if (response && !response.error) {
    yield put(updateDownloadSucess(response.data));
  } else {
    yield put({ type: types.UPDATEDOWNLOAD_ERROR, response });
  }
}
// dlete
export function* deleteDownloadSaga(data) {
  const response = yield call(deleteDownloadService, data);
  if (response && !response.error) {
    yield put(deleteDownloadSucess(response.data));
  } else {
    yield put({ type: types.DELETEDOWNLOAD_ERROR, response });
  }
}
