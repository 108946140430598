import React, { useEffect } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Toolbar from "./AddButton";
import ProductCard from "./cards";
import { getNews } from "../../store/action/news_eventsaction";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AllNews = useSelector((state) => state.getNewsReducer);

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);
  return (
    <Container maxWidth={false} style={{ marginTop: 30 }}>
      <Toolbar />
      <Box mt={3}>
        <Grid container spacing={3}>
          {AllNews.newsEvents &&
            AllNews.newsEvents.map((product) => (
              <Grid item key={product.id} lg={4} md={6} xs={12}>
                <ProductCard className={classes.productCard} News={product} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductList;
