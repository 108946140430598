import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Select,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Editor from "ckeditor4-react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import "./form.css";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    minWidth: 800,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  submitButton: {
    marginTop: "43px",
    background: "#FD6A02",
    textTransform: "none",
    color: theme.palette.text.disabled,
    "&$buttonDisabled": {
      color: theme.palette.text.disabled,
      background: theme.palette.background.paper,
    },
    "&:hover": {
      background: "#FD6A02",
      color: "#fff",
    },
  },
  submitText: {
    color: "#fff",
    "&$buttonDisabled": {
      color: "#000",
    },
  },
  fileUpload: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },
  textField: {
    width: "100%",
    marginTop: 30,
  },
}));
const LoginView = (props) => {
  let {
    values: {
      name,
      img,
      productCategory,
      subCategory,
      comment,
      shortdescription,
      longDescription,
      Features,
      specifications,
      backgroundiamge,
      additionalImages,
      featuredImage,
      datasheet_file,
    },

    handleBlur,
    handleSubmit,
    handleChange,
    ImageFile,
    touched,
    errors,
    handleBackgroundImage,
    handleAdditionalImage,
    handle_upload_FeaturedImage,
    handleDataSheetFIle,
    setFieldValue,
    handleDeletImage,
    loader,
    close,
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    Features: "",
  });

  const imgChangeHandler = (e, obj) => {
    var file = e.target.files;
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      ImageFile(file);
    }
  };

  const backgroundiamgehandler = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      handleBackgroundImage(file);
    }
  };
  const featuredImageHandler = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      handle_upload_FeaturedImage(file);
    }
  };

  const handleAdditionalimage = (e) => {
    var file = e.target.files;
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      handleAdditionalImage(e.target.files);
    }
  };
  const handleDataSheet = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      handleDataSheetFIle(file);
    }
  };
  const handleProductcat = (e) => {
    setFieldValue("productCategory", e.target.value);
  };
  const handleProductSubcat = (e) => {
    setFieldValue("subCategory", e.target.value);
  };
  const handle_longDescEditor = (event) => {
    setValues({
      ...values,
      Features: event.editor.getData(),
    });
    setFieldValue("Features", event.editor.getData());
  };
  const [inputList, setInputList] = useState(
    specifications ? specifications : [{ title: "", description: "" }]
  );
  const handleInputChange = (e, index) => {
    let val = inputList[index].title;
    if (e.target) {
      val = e.target.value;
    }
    let desc = inputList[index].description;
    if (e.editor) {
      desc = e.editor.getData();
    }

    const list = [...inputList];
    list[index].title = val;
    list[index].description = desc;

    setInputList(list);

    setFieldValue("specifications", list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setFieldValue("specifications", list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { title: "", description: "" }]);
  };
  const handleImageDelete = (obj) => {
    handleDeletImage(obj);
  };

  return (
    <Grid md={12} xs={12} sm={12} item className={classes.root}>
      <form onSubmit={handleSubmit} style={{ padding: 48, width: 900 }}>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Product category: <span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container style={{ marginTop: 16 }}>
            <Select
              native
              value={productCategory}
              fullWidth
              defaultValue={productCategory}
              variant="outlined"
              onChange={(event) => handleProductcat(event)}
              label="productCategory"
              inputProps={{
                name: "productCategory",
                id: "outlined-age-native-simple",
              }}
              required
            >
              <option aria-label="None" value="productCategory" />
              {props.allProductCategory.map((obj) => (
                <option value={obj}>{obj}</option>
              ))}
            </Select>
          </Grid>
        </Grid>
        {/* subcat */}
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Subcategory: <span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container style={{ marginTop: 16 }}>
            <Select
              native
              value={subCategory}
              fullWidth
              defaultValue={subCategory}
              variant="outlined"
              onChange={(event) => handleProductSubcat(event)}
              label="subCategory"
              inputProps={{
                name: "subCategory",
                id: "outlined-age-native-simple",
              }}
              required
            >
              <option aria-label="None" value="productCategory" />
              {props.allSubCategory.map((obj, i) => (
                <option value={obj}>{obj}</option>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ padding: "30px 0px" }}>
              Title:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>

          <Grid md={9} container>
            <TextField
              fullWidth
              helperText={touched.name ? errors.name : ""}
              error={touched.name && Boolean(errors.name)}
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="name"
              value={name}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ padding: "30px 0px" }}>Comment:</Typography>
          </Grid>

          <Grid md={9} container>
            <TextField
              helperText={touched.comment ? errors.comment : ""}
              error={touched.comment && Boolean(errors.comment)}
              fullWidth
              margin="normal"
              name="comment"
              onBlur={handleBlur}
              onChange={handleChange}
              type="comment"
              value={comment}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ padding: "30px 0px" }}>
              {" "}
              shortDescription :
            </Typography>
          </Grid>

          <Grid md={9} container>
            <TextField
              helperText={
                touched.shortdescription ? errors.shortdescription : ""
              }
              error={
                touched.shortdescription && Boolean(errors.shortdescription)
              }
              fullWidth
              margin="normal"
              name="shortdescription"
              onBlur={handleBlur}
              onChange={handleChange}
              type="shortdescription"
              value={shortdescription}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ padding: "30px 0px" }}>
              {" "}
              longDescription :<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>

          <Grid md={9} container>
            <TextField
              fullWidth
              margin="normal"
              name="longDescription"
              onBlur={handleBlur}
              onChange={handleChange}
              type="longDescription"
              value={longDescription}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ margin: "30px 0px" }}>
              Featured Image:
            </Typography>
          </Grid>
          <Grid md={9} container>
            <div style={{ display: "flex" }}>
              {featuredImage === "" ? null : (
                <Grid style={{ height: 100, width: 100 }}>
                  <img
                    src={featuredImage}
                    alt="img"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Grid>
              )}

              <TextField
                type="file"
                variant="outlined"
                onChange={featuredImageHandler}
                inputProps={{
                  accept: "image/png, image/jpg, image/jpeg",
                  id: "file",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        {img ? null : (
          <Grid md={12} style={{ display: "flex" }}>
            <Grid md={3} item>
              <Typography style={{ margin: "30px 0px" }}>
                Image:<span style={{ color: "tomato" }}>*</span>
              </Typography>
            </Grid>
            <Grid md={9} container>
              <Grid
                md={12}
                container
                direction="row"
                style={{ display: "block" }}
              >
                <TextField
                  type="file"
                  variant="outlined"
                  required={img ? false : true}
                  onChange={imgChangeHandler}
                  inputProps={{
                    accept: "image/png, image/jpg, image/jpeg",
                    id: "file",
                    multiple: true,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid md={12} container>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>Features:</Typography>
          </Grid>
          <Grid md={9} container style={{ marginTop: 24 }}>
            <Editor
              fullWidth
              className={classes.textField}
              data={Features ? Features : " "}
              config={{
                toolbar: [["BulletedList"]],
              }}
              label="longDesc"
              name="longDesc"
              onChange={handle_longDescEditor}
              value={Features}
            />
          </Grid>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ margin: "30px 0px" }}>
              Background Image:
            </Typography>
          </Grid>
          <Grid md={9} container>
            <div style={{ display: "flex" }}>
              {backgroundiamge === "" ? null : (
                <Grid style={{ height: 100, width: 100 }}>
                  <img
                    src={backgroundiamge}
                    alt="img"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Grid>
              )}

              <TextField
                required={backgroundiamge ? false : Features ? true : false}
                type="file"
                variant="outlined"
                onChange={backgroundiamgehandler}
                inputProps={{
                  accept: "image/png, image/jpg, image/jpeg",
                  id: "file",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        {additionalImages ? null : (
          <Grid style={{ display: "flex" }}>
            <Grid md={3} item>
              <Typography style={{ margin: "30px 0px" }}>
                additionalImages:<span style={{ color: "tomato" }}>*</span>
              </Typography>
            </Grid>
            <Grid md={9} container>
              <div style={{ display: "flex" }}>
                {additionalImages === "" ? null : (
                  <Grid style={{ height: 100, width: 100 }}>
                    <img
                      src={additionalImages && additionalImages[0]}
                      alt="img"
                      height="100%"
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </Grid>
                )}

                <TextField
                  type="file"
                  variant="outlined"
                  required={additionalImages ? false : true}
                  onChange={handleAdditionalimage}
                  inputProps={{
                    accept: "image/png, image/jpg, image/jpeg",
                    id: "file",
                    multiple: true,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        )}
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Specifications:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container>
            {inputList.map((x, i) => (
              <Grid md={12} container>
                <TextField
                  required
                  fullWidth
                  label="title"
                  margin="normal"
                  name="title"
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, i)}
                  type="title"
                  value={x.title}
                  variant="outlined"
                />

                <Editor
                  fullWidth
                  className={classes.textField}
                  data={x.description ? x.description : " "}
                  config={{
                    toolbar: [["Bold", "indent", "Image", "BulletedList"]],
                  }}
                  label="longDesc"
                  name="longDesc"
                  onChange={(e) => handleInputChange(e, i)}
                  value={x.description}
                  required
                />

                <Grid md={12} xs={12} sm={12} container item justify="flex-end">
                  {inputList.length !== 1 && (
                    <IconButton
                      onClick={() => handleRemoveClick(i)}
                      style={{ color: "#000" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {inputList.length - 1 === i && (
                    <IconButton
                      onClick={handleAddClick}
                      style={{ color: "#000" }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ margin: "30px 0px" }}>DataSheet:</Typography>
          </Grid>
          <Grid md={9} container>
            <div style={{ display: "flex" }}>
              <TextField
                type="file"
                variant="outlined"
                onChange={handleDataSheet}
                inputProps={{
                  accept: "application/pdf",
                  id: "file",
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Typography style={{ margin: 30 }}>
                {datasheet_file ? (
                  <a href={datasheet_file} rel="noreferrer" target="_blank">
                    <Typography>View DataSheet</Typography>
                  </a>
                ) : null}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid md={12} container item justify="center" style={{ marginTop: 20 }}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            style={{ background: "#FD6A02", textTransform: "none" }}
          >
            <Typography style={{ color: "#fff" }}>Submit</Typography>
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            style={{
              background: "#FD6A02",
              textTransform: "none",
              marginLeft: 24,
            }}
            onClick={() => close()}
          >
            <Typography style={{ color: "#fff" }}>Cancel</Typography>
          </Button>
          {loader ? (
            <CircularProgress style={{ color: "#FD6A02", marginLeft: 24 }} />
          ) : null}
        </Grid>
      </form>
    </Grid>
  );
};

export default LoginView;
