import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "left",
  },
  boldText: {
    fontSize: 16,
    color: "#646464",
    fontWeight: "bold",
  },
  card: {
    cursor: "pointer",
    marginRight: 40,
    padding: 20,
    display: "block",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
    },
  },
  BottomGrid: {
    position: "relative",
    top: "40px",
    [theme.breakpoints.down("sm")]: {
      top: "10px",
    },
  },
  button: {
    textTransform: "none",
    color: "#3f51b5",
  },
}));
const Contact = (props) => {
  const { data } = props;

  const classes = useStyles();

  return (
    <Box
      bgcolor="white"
      alignItems="center"
      justifyContent="center"
      boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
      fontSize="h6.fontSize"
      borderRadius="8px"
      marginBottom="30px"
      className={classes.card}
      key={data.fullName}
    >
      <Grid md={12} sm={12} xs={12} item container justify="space-between">
        {data.Product_id ? (
          <Typography style={{ fontWeight: 700 }}>
            {" "}
            {data.product_name}
          </Typography>
        ) : (
          <Typography style={{ fontWeight: 700 }}> General Enquiry</Typography>
        )}
        <Typography>
          Applied On:
          <span style={{ fontWeight: 700 }}>
            {data?.Date ? new Date(data?.Date)?.toLocaleDateString() : null}
          </span>
        </Typography>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={4} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Name: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>{data.fullName}</Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={4} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Phone Number: </span>
          </Typography>
        </Grid>
        <Grid md={4} item>
          <Typography className={classes.text}>{data.phone}</Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={4} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Email: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <a href={`mailto:${data.email}`} style={{ color: "#000" }}>
              {" "}
              {data.email}
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={4} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Location: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>{data.location}</Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={4} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Message: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>{data.message}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Contact;
