import { put, call } from "redux-saga/effects";
import {
  getAllVacancy,
  addVacancyService,
  getAllApplication,
  updateVacancyService,
  deleteVacncyService,
} from "../service/career";
import {
  getAllVacancySuccess,
  addvacancySucess,
  getAllApplicationSuccess,
  updateVacancySucess,
  deleteVacancySucess,
} from "../action/careeraction";
import * as types from "../action.type";
export function* getVacancySaga(data) {
  const response = yield call(getAllVacancy, data);
  if (response && !response.error) {
    yield put(getAllVacancySuccess(response.data));
  } else {
    yield put({ type: types.GETVACANCY_ERROR, response });
  }
}
// add vacancy
export function* AddVacancySaga(data) {
  const response = yield call(addVacancyService, data);
  if (response && !response.error) {
    yield put(addvacancySucess(response.data));
  } else {
    yield put({ type: types.ADD_VACANCY_ERROR, response });
  }
}
// update vacancy
export function* updateVacancySaga(data) {
  const response = yield call(updateVacancyService, data);

  if (response && !response.error) {
    yield put(updateVacancySucess(response.data));
  } else {
    yield put({ type: types.UPDATE_VACANCY_ERROR, response });
  }
}
// delete vacancy
export function* deleteVacancySaga(data) {
  const response = yield call(deleteVacncyService, data);
  if (response && !response.error) {
    yield put(deleteVacancySucess(response.data));
  } else {
    yield put({ type: types.DELETE_VACANCY_ERROR, response });
  }
}

export function* getApplicationSaga(data) {
  const response = yield call(getAllApplication, data);
  if (response && !response.error) {
    yield put(getAllApplicationSuccess(response.data));
  } else {
    yield put({ type: types.GET_APLICATION_ERROR, response });
  }
}
