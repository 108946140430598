import React from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },
}));
const LoginView = (props) => {
  let {
    values: { title, documentFile, docUrl },
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    handleDocumentFile,
    loader,
  } = props;

  const handleDocument = (e) => {
    var file = e.target.files[0];

    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      handleDocumentFile(file);
    }
  };
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} style={{ padding: 48, width: 900 }}>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} item>
          <Typography style={{ padding: 30 }}>
            Title:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>

        <Grid md={9} container>
          <TextField
            helperText={touched.title ? errors.title : ""}
            error={touched.title && Boolean(errors.title)}
            fullWidth
            label="title"
            margin="normal"
            name="title"
            onBlur={handleBlur}
            onChange={handleChange}
            type="title"
            value={title}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <Grid style={{ display: "flex" }}>
        <Grid md={3} item>
          <Typography style={{ margin: 30 }}>
            Document:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>
        <Grid md={9} container>
          <div style={{ display: "flex" }}>
            <TextField
              type="file"
              variant="outlined"
              required={docUrl ? false : true}
              onChange={handleDocument}
              inputProps={{
                accept: "application/pdf , .csv",
                id: "file",
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <Typography style={{ margin: 30 }}>
              {documentFile ? (
                <a href={documentFile} target="_blank" rel="noreferrer">
                  <Typography>View DocumentFile</Typography>
                </a>
              ) : null}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        md={12}
        container
        item
        justify="center"
        style={{ marginTop: 20, display: "flex" }}
      >
        <Button
          size="large"
          type="submit"
          variant="contained"
          style={{ background: "#FD6A02", textTransform: "none" }}
        >
          <Typography style={{ color: "#fff" }}>Submit</Typography>
        </Button>
        {loader ? (
          <CircularProgress style={{ color: "#FD6A02", marginLeft: 24 }} />
        ) : null}
      </Grid>
    </form>
  );
};

export default LoginView;
