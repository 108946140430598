import React, { useEffect, useState } from "react";
import { getProductAction } from "../../store/action/productAction";
import { getProductCat } from "../../store/action/productcategory";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "./productCards";
import { Typography } from "@material-ui/core";

const Product = () => {
  const dispatch = useDispatch();
  const AllProduct = useSelector((state) => state.getProductReducer);

  const AllProductCat = useSelector((state) => state.getProductCat);

  const [params, setparams] = useState();
  useEffect(() => {
    let params = new URLSearchParams(window.location.search).get("product");
    setparams(params);
    dispatch(getProductCat());
    dispatch(getProductAction(params));
  }, [dispatch]);
  let filterProductCat = AllProductCat?.allProductCat
    ?.filter((item) => item._id === params)
    .map((obj) => obj.title);
  return (
    <div>
      {params ? (
        <ProductCard
          data={AllProduct.allProduct}
          params={filterProductCat ? [...filterProductCat] : ""}
        />
      ) : (
        <Typography
          style={{
            fontSize: 24,
            color: "#646464",
            fontWeight: 700,
            padding: 32,
          }}
        >
          Please select Any Product Category
        </Typography>
      )}
    </div>
  );
};

export default Product;
