import { put, call } from "redux-saga/effects";
import {
  addNews,
  getNews,
  updateNewsService,
  deleteNewsService,
} from "../service/news_events";
import {
  addNewsSucess,
  getNewsSuccess,
  updateNewsSuccess,
  deleteNewsSuccess,
} from "../action/news_eventsaction";
import * as types from "../action.type";
export function* AddNewsSaga(data) {
  const response = yield call(addNews, data);
  if (response && !response.error) {
    yield put(addNewsSucess(response.data));
  } else {
    yield put({ type: types.ADD_NEWS_EVENTS_ERROR, response });
  }
}
export function* getNewsSaga(data) {
  const response = yield call(getNews, data);
  if (response && !response.error) {
    yield put(getNewsSuccess(response.data));
  } else {
    yield put({ type: types.GET_NEWS_EVENTS_ERROR, response });
  }
}
export function* updateNewsSaga(data) {
  const response = yield call(updateNewsService, data);
  if (response && !response.error) {
    yield put(updateNewsSuccess(response.data));
  } else {
    yield put({ type: types.UPDATE_NEWS_EVENTS_ERROR, response });
  }
}
export function* deleteNewsSaga(data) {
  const response = yield call(deleteNewsService, data);
  if (response && !response.error) {
    yield put(deleteNewsSuccess(response.data));
  } else {
    yield put({ type: types.DELETE_NEWS_EVENTS_ERROR, response });
  }
}
