import * as types from "../action.type";
export const addProductCat = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.ADD_PRODUCT_CATEGORY_SUCCESS:
      return response;
    case types.ADD_PRODUCT_CATEGORY_ERROR:
      return state;
    default:
      return state;
  }
};
export const getProductCat = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GET_PRODUCT_CATEGORY_SUCCESS:
      return response;
    case types.GET_PRODUCT_CATEGORY_ERROR:
      return state;
    default:
      return state;
  }
};
// UPDATE

export const updateProductCat = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return response;
    case types.UPDATE_PRODUCT_CATEGORY_ERROR:
      return state;
    default:
      return state;
  }
};
// DELETE

export const deleteProductCat = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.DELETE_PRODUCT_CATEGORY_SUCCESS:
      return response;
    case types.DELETE_PRODUCT_CATEGORY_ERROR:
      return state;
    default:
      return state;
  }
};
