import React from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";

import Form from "./form";
import { loginValidation } from "./auth.validation";
import { login, authCheck } from "../../store/action/signupaction";

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.LoginReducer);

  const { close, _auth } = props;

  const initialval = {
    email: "",
    password: "",
  };

  const handleSubmit = (val) => {
    props.login({
      email: val.email,
      password: val.password,
    });
    dispatch(authCheck());

    if (authStatus.success) {
      navigate("/app/dashboard");
    }
  };

  return (
    <Formik
      initialValues={initialval}
      close={close}
      onSubmit={handleSubmit}
      validationSchema={loginValidation}
      response={login}
      auth={_auth}
    >
      {(props) => <Form {...props} authStatus={authStatus} />}
    </Formik>
  );
};
const mapStateToProps = ({ LoginReducer, authCheckReducer }) => {
  return { _login: LoginReducer, _auth: authCheckReducer };
};
export default connect(mapStateToProps, { login, authCheck })(SignUpForm);
