import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ProductCategoryUpdate from "../../productCategory/ProductListView/AddProductCat";

import { deletProductCat } from "../../../store/action/productcategory";
import { deleteNews } from "../../../store/action/news_eventsaction";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ProductCard = ({ className, product, News, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const delete_product_cat = useSelector((state) => state.deleteProductCat);
  // const delete_news = useSelector((state) => state.deleteNewsReducers);
  const handleDelte = (id) => {
    dispatch(deletProductCat(id));
    if (News) {
      dispatch(deleteNews(id));
    }
    window.location.reload();
  };

  const [open, setopen] = useState(false);

  const handleOpen = (data) => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent style={{ padding: 0 }}>
        <Grid style={{ height: 200, position: "relative" }}>
          <img
            alt="proimg"
            src={product ? product.imgUrl : null}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Grid>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2} style={{ display: "flex" }}>
        <Grid container justify="space-between" spacing={2}>
          <Typography align="center" color="textPrimary" variant="body1">
            {product ? product.title : null}
          </Typography>
        </Grid>
        <Box style={{ marginRight: 20 }}>
          <IconButton
            onClick={() => handleOpen(product)}
            style={{ padding: 0 }}
          >
            <EditIcon fontSize="small" style={{ color: "#000000" }} />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => handleDelte(product ? product._id : null)}
          >
            <DeleteIcon fontSize="small" style={{ color: "#000000" }} />
          </IconButton>
        </Box>
      </Box>

      <ProductCategoryUpdate
        open={open}
        onClose={handleClose}
        ProductData={product}
      />
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
};

export default ProductCard;
