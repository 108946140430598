import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Page from "src/components/Page";
import Toolbar from "./toolbar";
import ProductCard from "./card";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const ProductList = (props) => {
  const { data, params } = props;

  const classes = useStyles();
  console.log("params", params);
  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Grid
          md={12}
          xs={12}
          sm={12}
          item
          container
          direction="row"
          justify="space-between"
          style={{ marginTop: 32 }}
        >
          <Grid md={6} xs={6} sm={6} item>
            {data && data.length < 1 ? null : (
              <Typography
                style={{
                  fontSize: 24,
                  color: "#646464",
                  fontWeight: 700,
                }}
              >
                Products | {params}
              </Typography>
            )}
            {data && data.length < 1 ? (
              <Typography
                style={{
                  fontSize: 24,
                  color: "#646464",
                  fontWeight: 700,
                }}
              >
                Product Not Found | {params}
              </Typography>
            ) : null}
          </Grid>
          <Grid md={6} xs={6} sm={6} item>
            <Toolbar />
          </Grid>
        </Grid>
        <Grid md={12} xs={12} sm={12} item>
          <Box mt={3}>
            <Grid container spacing={3}>
              {data &&
                data.map((product) => (
                  <Grid item key={product.id} lg={4} md={6} xs={12}>
                    <ProductCard
                      className={classes.productCard}
                      product={product}
                      params={params}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Page>
  );
};

export default ProductList;
