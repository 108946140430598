import React, { useState } from "react";

import {
  Button,
  TextField,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },
}));

const LoginView = (props) => {
  const classes = useStyles();

  let {
    values: { Title, shortDesc, city, country },
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    JOb_descFile,
    loader,
    close,
  } = props;
  const [JobPdf, setJobPdf] = useState();
  const UploadJob_desc = (e) => {
    var file = e.target.files[0];

    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      JOb_descFile(file);
      setJobPdf(file.name);
    }
  };
  return (
    <form onSubmit={handleSubmit} style={{ padding: 48, width: 800 }}>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>Title:</Typography>
        </Grid>

        <Grid md={9} container justify="flex-end">
          <TextField
            error={Boolean(touched.Title && errors.Title)}
            fullWidth
            helperText={touched.Title && errors.Title}
            label="Title"
            margin="normal"
            name="Title"
            onBlur={handleBlur}
            onChange={handleChange}
            type="Title"
            value={Title}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>Short Description:</Typography>
        </Grid>

        <Grid md={9} container justify="flex-end">
          <TextField
            error={Boolean(touched.shortDesc && errors.shortDesc)}
            fullWidth
            helperText={touched.shortDesc && errors.shortDesc}
            label="shortDesc"
            margin="normal"
            name="shortDesc"
            onBlur={handleBlur}
            onChange={handleChange}
            type="shortDesc"
            value={shortDesc}
            required
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>City:</Typography>
        </Grid>

        <Grid md={9} container justify="flex-end">
          <TextField
            error={Boolean(touched.city && errors.city)}
            fullWidth
            helperText={touched.city && errors.city}
            label="city"
            margin="normal"
            name="city"
            onBlur={handleBlur}
            onChange={handleChange}
            type="city"
            value={city}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>Country:</Typography>
        </Grid>

        <Grid md={9} container justify="flex-end">
          <TextField
            error={Boolean(touched.country && errors.country)}
            fullWidth
            helperText={touched.country && errors.country}
            label="country"
            margin="normal"
            name="country"
            onBlur={handleBlur}
            onChange={handleChange}
            type="country"
            value={country}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>Job Description:</Typography>
        </Grid>

        <Grid md={9} container>
          <TextField
            type="file"
            variant="outlined"
            required
            onChange={UploadJob_desc}
            inputProps={{
              accept: "application/pdf",
              id: "file",
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <Typography style={{ margin: 30 }}>
            {JobPdf ? JobPdf : null}
          </Typography>
        </Grid>
      </Grid>

      <Grid md={12} container item justify="center" style={{ marginTop: 20 }}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          style={{ background: "#FD6A02", textTransform: "none" }}
        >
          <Typography style={{ color: "#fff" }}>Submit</Typography>
        </Button>
        <Button
          size="large"
          type="submit"
          variant="contained"
          style={{
            background: "#FD6A02",
            textTransform: "none",
            marginLeft: 24,
          }}
          onClick={() => close()}
        >
          <Typography style={{ color: "#fff" }}>Cancel</Typography>
        </Button>
        {loader ? (
          <CircularProgress style={{ color: "#FD6A02", marginLeft: 24 }} />
        ) : null}
      </Grid>
    </form>
  );
};

export default LoginView;
