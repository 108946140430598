import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    minWidth: 800,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  submitButton: {
    marginTop: "43px",
    background: "#FD6A02",
    textTransform: "none",
    color: theme.palette.text.disabled,
    "&:buttonDisabled": {
      color: theme.palette.text.disabled,
      background: theme.palette.background.paper,
    },
    "&:hover": {
      background: "#FD6A02",
      color: "#fff",
    },
  },
  submitText: {
    color: "#fff",
    "&:buttonDisabled": {
      color: "#000",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },
}));

const LoginView = (props) => {
  let {
    values: { title, img, shortDesc, iconImage, subCategory },
    handleBlur,
    handleSubmit,
    handleChange,
    ImageFile,
    loader,
    iconImagefile,
    setFieldValue,
  } = props;

  const classes = useStyles();

  const imgChangeHandler = (e) => {
    var file = e.target.files[0];

    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      ImageFile(file);
    }
  };
  const iconImageHandler = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      iconImagefile(file);
    }
  };
  // subcat
  const [inputList, setInputList] = useState(subCategory ? subCategory : [""]);
  const handleInputChange = (e, index) => {
    let val = inputList[index];
    if (e.target) {
      val = e.target.value;
    }

    const list = [...inputList];
    list[index] = val;

    setInputList(list);

    setFieldValue("subCategory", list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setFieldValue("subCategory", list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  return (
    <Grid md={12} xs={12} sm={12} item className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid item style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ padding: 30 }}>
              Title:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>

          <Grid md={9} container>
            <TextField
              fullWidth
              label="title"
              margin="normal"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              type="title"
              value={title}
              required
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ padding: 30 }}>
              Short Description:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>

          <Grid md={9} container>
            <TextField
              fullWidth
              label="shortDesc"
              margin="normal"
              name="shortDesc"
              onBlur={handleBlur}
              onChange={handleChange}
              type="shortDesc"
              value={shortDesc}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>

        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ margin: 30 }}>
              Image:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container>
            <div style={{ display: "flex" }}>
              {img === "" ? null : (
                <Grid style={{ height: 150, width: 150 }}>
                  <img
                    src={img}
                    alt="img"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Grid>
              )}

              <TextField
                id="img"
                type="file"
                name="img"
                variant="outlined"
                onChange={imgChangeHandler}
                inputProps={{
                  accept: "image/png, image/jpg, image/jpeg",
                  id: "file",
                  multiple: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                required={img ? false : true}
              />
            </div>
          </Grid>
        </Grid>
        {/* iconimage   field*/}

        <Grid style={{ display: "flex" }}>
          <Grid md={3} item>
            <Typography style={{ margin: 30 }}>
              IconImage:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container>
            <div style={{ display: "flex" }}>
              {iconImage === "" ? null : (
                <Grid style={{ height: 100, width: 100, margin: "16px 0px" }}>
                  <img
                    src={iconImage}
                    alt="iconImage"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Grid>
              )}

              <TextField
                id="iconImage"
                type="file"
                name="iconImage"
                variant="outlined"
                onChange={iconImageHandler}
                inputProps={{
                  accept: "image/png, image/jpg, image/jpeg",
                  id: "file",
                  multiple: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                required={iconImage ? false : true}
              />
            </div>
          </Grid>
        </Grid>
        {/* subCateory */}
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              SubCategory:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container>
            {inputList.map((x, i) => (
              <Grid md={12} container>

                <TextField
                  // required
                  fullWidth
                  label="subCategory"
                  margin="normal"
                  name="subCategory"
                  onBlur={handleBlur}
                  onChange={(e) => handleInputChange(e, i)}
                  type="subCategory"
                  value={x}
                  variant="outlined"
                />

                <Grid md={12} xs={12} sm={12} container item justify="flex-end">
                  {inputList.length !== 1 && (
                    <IconButton
                      onClick={() => handleRemoveClick(i)}
                      style={{ color: "#000" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {inputList.length - 1 === i && (
                    <IconButton
                      onClick={handleAddClick}
                      style={{ color: "#000" }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          md={12}
          container
          item
          justify="center"
          style={{ marginTop: 20, display: "flex" }}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            classes={{
              root: classes.submitButton,
            }}
          >
            <Typography className={classes.submitText}>Submit</Typography>
          </Button>
          {loader ? (
            <CircularProgress style={{ color: "#FD6A02", marginLeft: 24 }} />
          ) : null}
        </Grid>
      </form>
    </Grid>
  );
};

export default LoginView;
