import * as types from "../action.type";
// add
export const addDownload = (data) => {
  return {
    type: types.ADDDOWNLOAD,
    data,
  };
};
export const addDownloadSucess = (data) => {
  return {
    type: types.ADDDOWNLOAD_SUCCESS,
    data,
  };
};

export const addDownloadError = (data) => {
  return {
    type: types.ADDDOWNLOAD_ERROR,
    data,
  };
};
//get
export const getDownload = (data) => {
  return {
    type: types.GETDOWNLOAD,
    data,
  };
};
export const getDownloadSuccess = (data) => {
  return {
    type: types.GETDOWNLOAD_SUCCESS,
    data,
  };
};

export const getDownloadError = (data) => {
  return {
    type: types.GETDOWNLOAD_ERROR,
    data,
  };
};
// update
export const updateDownload = (data) => {
  return {
    type: types.UPDATEDOWNLOAD,
    data,
  };
};
export const updateDownloadSucess = (data) => {
  
  return {
    type: types.UPDATEDOWNLOAD_SUCCESS,
    data,
  };
};

export const updateDownloadError = (data) => {
  return {
    type: types.UPDATEDOWNLOAD_ERROR,
    data,
  };
};
// DELETE
export const deleteDownload = (data) => {
  return {
    type: types.DELETEDOWNLOAD,
    data,
  };
};
export const deleteDownloadSucess = (data) => {
  return {
    type: types.DELETEDOWNLOAD_SUCCESS,
    data,
  };
};

export const deleteDownloadError = (data) => {
  return {
    type: types.DELETEDOWNLOAD_ERROR,
    data,
  };
};
