import React, { useState } from "react";
import {
  Button,
  TextField,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
  Select,
} from "@material-ui/core";
import Editor from "ckeditor4-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./form.css";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
  textField: {
    width: "100%",
    marginTop: 30,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },
}));

const LoginView = (props) => {
  const classes = useStyles();

  let {
    values: { title, shortDesc, tags, longDesc, place, link, img, type, time },
    handleBlur,
    handleSubmit,
    handleChange,
    ImageFile,
    setFieldValue,
    loader,
    touched,
    errors,
    close,
  } = props;

  const [values, setValues] = useState({
    longDesc: "",
  });

  const [Type, setType] = useState(type ? type : null);

  const imgChangeHandler = (e) => {
    var file = e.target.files[0];
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      ImageFile(file);
    }
  };

  const handle_longDescEditor = (event) => {
    setValues({
      ...values,
      longDesc: event.editor.getData(),
    });
    setFieldValue("longDesc", event.editor.getData());
  };

  const handleType = (e) => {
    setFieldValue("type", e.target.value);
    setType(e.target.value);
  };
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(time ? time : "2014-08-18T21:11:54")
  );

  const handleDateChange = (selected_Date) => {
    setSelectedDate(selected_Date);
    setFieldValue("date", selected_Date);
    setFieldValue("time", selected_Date);
  };
  const handleTagsChange = (e) => {
    var names = e.target.value;
    var nameArr = names.split(",");

    setFieldValue("tags", nameArr);
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: 48, width: 900 }}>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>
            Type:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>
        <Grid md={9} container style={{ marginTop: 16 }}>
          <Select
            native
            value={Type}
            fullWidth
            defaultValue={type}
            variant="outlined"
            onChange={(event) => handleType(event)}
            label="type"
            inputProps={{
              name: "type",
              id: "outlined-age-native-simple",
            }}
            required
            error={Boolean(touched.type && errors.type)}
            helperText={touched.type && errors.type}
          >
            <option aria-label="None" value={""} />
            <option value={"news"}>news</option>
            <option value={"events"}>events</option>
          </Select>
        </Grid>
      </Grid>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>
            Image:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>

        <Grid md={9} container>
          {img === "" ? null : (
            <Grid
              style={{
                height: 150,
                width: 150,
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <img
                src={img}
                alt="img"
                height="100%"
                width="100%"
                style={{ objectFit: "cover" }}
              />
            </Grid>
          )}

          <TextField
            type="file"
            variant="outlined"
            required={img ? false : true}
            onChange={imgChangeHandler}
            inputProps={{
              accept: "image/png, image/jpg, image/jpeg",
              id: "file",
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>
            Title:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>

        <Grid md={9} container justify="flex-end">
          <TextField
            fullWidth
            label="title"
            margin="normal"
            name="title"
            onBlur={handleBlur}
            onChange={handleChange}
            type="title"
            value={title}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>
            Short Description:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>
        <Grid md={9} container justify="flex-end">
          <TextField
            fullWidth
            label="shortDesc"
            margin="normal"
            name="shortDesc"
            onBlur={handleBlur}
            onChange={handleChange}
            type="shortDesc"
            value={shortDesc}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      {type !== "news" ? null : (
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Long Description:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container justify="flex-end" style={{ marginTop: 24 }}>
            <Editor
              fullWidth
              className={classes.textField}
              data={longDesc ? longDesc : ""}
              config={{
                toolbar: [["Bold", "Blockquote", "Link", "indent"]],
              }}
              label="longDesc"
              name="longDesc"
              onChange={handle_longDescEditor}
              value={longDesc}
              required
            />
            {longDesc ? null : (
              <Typography style={{ fontSize: "12px", color: "tomato" }}>
                Long description is required
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      {type === "events" ? (
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Place:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container justify="flex-end">
            <TextField
              fullWidth
              label="place"
              margin="normal"
              name="place"
              onBlur={handleBlur}
              onChange={handleChange}
              type="place"
              value={place}
              variant="outlined"
              // required
            />
          </Grid>
        </Grid>
      ) : null}
      {type === "events" ? (
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Date:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container justify="flex-end">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  inputVariant="outlined"
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date picker inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  required
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      ) : null}
      {type === "events" ? (
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Time:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container justify="flex-end">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardTimePicker
                  inputVariant="outlined"
                  margin="normal"
                  fullWidth
                  id="time-picker"
                  label="Time picker"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  required
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      ) : null}

      {type === "events" ? (
        <Grid style={{ display: "flex" }}>
          <Grid md={3} style={{ position: "relative", top: 30 }}>
            <Typography>
              Link:<span style={{ color: "tomato" }}>*</span>
            </Typography>
          </Grid>
          <Grid md={9} container justify="flex-end">
            <TextField
              fullWidth
              label="link"
              margin="normal"
              name="link"
              onBlur={handleBlur}
              onChange={handleChange}
              type="link"
              value={link}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid style={{ display: "flex" }}>
        <Grid md={3} style={{ position: "relative", top: 30 }}>
          <Typography>
            Tags:<span style={{ color: "tomato" }}>*</span>
          </Typography>
        </Grid>
        <Grid md={9} container justify="flex-end">
          <TextField
            fullWidth
            label="tags"
            margin="normal"
            name="tags"
            onBlur={handleBlur}
            onChange={(e) => handleTagsChange(e)}
            type="tags"
            value={tags}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>

      <Grid md={12} container item justify="center" style={{ marginTop: 20 }}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          style={{ background: "#FD6A02", textTransform: "none" }}
        >
          <Typography style={{ color: "#fff" }}>Submit</Typography>
        </Button>
        <Button
          size="large"
          type="submit"
          variant="contained"
          style={{
            background: "#FD6A02",
            textTransform: "none",
            marginLeft: 24,
          }}
          onClick={() => close()}
        >
          <Typography style={{ color: "#fff" }}>Cancel</Typography>
        </Button>
        {loader ? (
          <CircularProgress style={{ color: "#FD6A02", marginLeft: 24 }} />
        ) : null}
      </Grid>
    </form>
  );
};

export default LoginView;
