import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Typography, Grid, Button } from "@material-ui/core";

import Form from "./form";
import {
  addDownload,
  updateDownload,
} from "../../store/action/donwloadaction.js";

const AddProductCategory = (props) => {
  const [document, setDocument] = useState();
  const [params, setparams] = useState();

  const dispatch = useDispatch();

  const updateDownloadResponse = useSelector((state) => state.updateDownload);
  const addDownloadResponse = useSelector((state) => state.addDownload);

  const { close, onClose, open, Data } = props;

  const [loader, setloader] = useState(false);
  const ShowLoader = () => {
    setloader(true);
  };

  const initialval = {
    title: Data ? Data.title : "",
    docUrl: Data ? Data.docUrl : "",
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search).get("download");
    setparams(params);
  }, []);
  const handleDocument = (url) => {
    setDocument(url);
  };

  const handleSubmit = (val) => {
    ShowLoader();
    if (!Data) {
      dispatch(
        addDownload({
          title: val.title,
          docUrl: document,
          type: params,
        })
      );
    }

    if (Data) {
      dispatch(
        updateDownload({
          id: Data && Data._id,
          title: val.title ? val.title : Data && Data.title,
          docUrl: document ? document : Data && Data.docUrl,
          type: params,
        })
      );
    }

    if (addDownloadResponse.success) {
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      style={{ marginLeft: 210 }}
    >
      {addDownloadResponse.success || updateDownloadResponse.success ? null : (
        <Typography style={{ margin: 20 }}>
          All <span style={{ color: "tomato" }}>*</span> fields are required
        </Typography>
      )}
      {addDownloadResponse.success || updateDownloadResponse.success ? (
        <Grid
          container
          justify="center"
          style={{ padding: 100, minWidth: 900, display: "grid" }}
        >
          <Typography style={{ textAlign: "center" }}>
            Document {updateDownloadResponse.success ? "update" : "Add"}{" "}
            Successfully
          </Typography>
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClose}
            style={{
              background: "#FD6A02",
              textTransform: "none",
              marginTop: 24,
            }}
          >
            <Typography style={{ color: "#fff" }}>Close</Typography>
          </Button>
        </Grid>
      ) : (
        <Formik
          initialValues={initialval}
          close={close}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form
              {...props}
              response={addDownloadResponse}
              loader={loader}
              handleDocumentFile={(url) => handleDocument(url)}
            />
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default AddProductCategory;
