import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Cards from "./cards";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddVacancyModal from "../allVacancy/AddVacancyModel";
import {
  getAllVacancy,
  deleteVacancy,
} from "../../../store/action/careeraction";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: 48,
    marginLeft: 20,
    flexWrap: "wrap",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
const Contact = () => {
  const dispatch = useDispatch();

  const AllVacancy = useSelector((state) => state.getAllVacancyReducer);
  // const DeleteVacany = useSelector((state) => state.deleteVacancyReducer);
  const [open, setopen] = useState(false);
  useEffect(() => {
    dispatch(getAllVacancy());
  }, [dispatch]);

  const classes = useStyles();
  const handleClick = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteVacancy(id));
    window.location.reload();
  };
  return (
    <Grid md={12} xs={12} sm={12} item>
      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        justify="flex-end"
        style={{ marginTop: 32, paddingRight: 32 }}
      >
        {/* <Button
          variant="contained"
          style={{ background: "#3f51b5", textTransform: "none" }}
          onClick={() => handleClick()}
        >
          <Typography style={{ color: "#fff" }}> Add Vacancy</Typography>
        </Button> */}
        <Button
          variant="contained"
          style={{ backgroundColor: "#FD6A02" }}
          onClick={() => handleClick()}
        >
          <AddOutlinedIcon
            style={{
              backgroundColor: "#fff",
              color: "#FD6A02",
              fontSize: 16,
              marginRight: 16,
              marginTop: 4,
              borderRadius: 2,
            }}
          />

          <Typography style={{ color: "#fff", fontWeight: 700 }}>
            Add New
          </Typography>
        </Button>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        spacing={4}
        className={classes.root}
      >
        {AllVacancy.Jobs &&
          AllVacancy.Jobs.map((obj, index) => {
            return (
              <Grid md={6} xs={12} sm={12} item>
                <Cards data={obj} handleDelete={handleDelete} />
              </Grid>
            );
          })}
      </Grid>
      <AddVacancyModal open={open} onClose={handleClose} />
    </Grid>
  );
};
export default Contact;
