import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  submitButton: {
    background: "#FD6A02",
    color: "#fff",
    "&$buttonDisabled": {
      color: theme.palette.text.disabled,
      background: theme.palette.background.paper,
    },
    "&:hover": {
      background: "#FD6A02",
      color: "#fff",
    },
  },
  backendresponse: {
    textAlign: "center",
    color: "#DB162F",
  },
}));

const LoginView = (props) => {
  const classes = useStyles();

  let {
    values: { password, confirmPassword, newpassword },
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    handleChange,
    updatePasswordResponse,
  } = props;

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2" align="center">
                Change Password
              </Typography>
            </Box>

            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={password}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.newPassword && errors.newPassword)}
              fullWidth
              helperText={touched.newPassword && errors.newPassword}
              label="newpassword"
              margin="normal"
              name="newpassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type="newpassword"
              value={newpassword}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              fullWidth
              label="confirmPassword"
              margin="normal"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type="confirmPassword"
              value={confirmPassword}
              variant="outlined"
            />
            {updatePasswordResponse.success === false ? (
              <Typography className={classes.backendresponse}>
                {updatePasswordResponse.message}
              </Typography>
            ) : (
              ""
            )}
            <Box my={2}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!(isValid && dirty)}
                classes={{
                  root: classes.submitButton,
                  disabled: classes.buttonDisabled,
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
