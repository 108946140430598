import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Form from "./form";
import { useDispatch, connect } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  makeStyles,
} from "@material-ui/core";
import {
  updatePassword,
  authCheck,
  Logout,
} from "../../store/action/signupaction";
import { useSelector } from "react-redux";
// import { changePasswordValidation } from "../auth/auth.validation";
const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    right: -10,
    top: 50,
    height: 200,
    padding: 24,
    alignItems: "center",
  },
}));
const UpdatePassword_UI = (props) => {
  const { close, _auth } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatePassword = useSelector((state) => state.updatePassword);

  const initialval = {
    password: "",
    newpassword: "",
    confirmPassword: "",
  };
  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);
  const [Open, setOpen] = useState(false);
  const [logoutmodel, setlogoutmodel] = useState(false);

  const handleOpenDailog = () => {
    setOpen(true);
  };

  const handleSubmit = (val) => {
    props.updatePassword({
      id: _auth.user._id,
      password: val.password,
      newpassword: val.newpassword,
      confirmPassword: val.confirmPassword,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const hanldeLogout = () => {
    dispatch(Logout());
    window.location.replace("/");
  };
  const hanldeLogoutOpen = () => {
    setlogoutmodel(true);
  };
  const handleLogoutCancel = () => {
    setlogoutmodel(false);
  };

  return (
    <Grid container md={12} xs={12} sm={12} item justify="flex-end">
      <Button
        variant="text"
        style={{ textTransform: "none", marginRight: 32 }}
        onClick={() => handleOpenDailog()}
      >
        <Typography style={{ color: "#fff" }}>Change Password</Typography>
      </Button>
      <Button
        variant="text"
        style={{ textTransform: "none" }}
        onClick={() => hanldeLogoutOpen()}
      >
        <Typography style={{ color: "#fff" }}>Logout</Typography>
      </Button>
      <Dialog
        open={logoutmodel}
        onClose={handleLogoutCancel}
        style={{ position: "absolute", top: 0 }}
        classes={{
          paper: classes.dialog,
        }}
      >
        <Typography style={{ marginTop: 50 }}>
          Do you want ,If yes{" "}
          <Button onClick={() => hanldeLogout()}>
            <span style={{ color: "tomato" }}>Logout</span>
          </Button>
          else{" "}
          <Button onClick={() => handleLogoutCancel()}>
            {" "}
            <span style={{ color: "green" }}>Cancel</span>
          </Button>
        </Typography>
      </Dialog>
      <Dialog open={Open} onClose={handleClose}>
        <Formik
          initialValues={initialval}
          close={close}
          onSubmit={handleSubmit}
          // validationSchema={changePasswordValidation}
        >
          {(props) => (
            <Form {...props} updatePasswordResponse={updatePassword} />
          )}
        </Formik>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = ({ updatePassword, authCheckReducer }) => {
  return { _update: updatePassword, _auth: authCheckReducer };
};
export default connect(mapStateToProps, { updatePassword, authCheck })(
  UpdatePassword_UI
);
