import * as types from "../action.type";
export const addDownload = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.ADDDOWNLOAD_SUCCESS:
      return response;
    case types.ADDDOWNLOAD_ERROR:
      return state;
    default:
      return state;
  }
};
export const getDownload = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GETDOWNLOAD_SUCCESS:
      return response;
    case types.GETDOWNLOAD_ERROR:
      return state;
    default:
      return state;
  }
};
// UPDATE

export const updateDownload = (state = {}, action) => {
  const response = action.data;
  switch (action.type) {
    case types.UPDATEDOWNLOAD_SUCCESS:
      return response;
    case types.UPDATEDOWNLOAD_ERROR:
      return state;
    default:
      return state;
  }
};
// DELETE

export const deleteDownload = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.DELETEDOWNLOAD_SUCCESS:
      return response;
    case types.DELETEDOWNLOAD_ERROR:
      return state;
    default:
      return state;
  }
};
