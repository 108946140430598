import React, { useEffect } from "react";
import "./index.css";
import ThreeSixty from "@mladenilic/threesixty.js";
import { Grid } from "@material-ui/core";
const Images360 = (props) => {
  useEffect(() => {
    new ThreeSixty(document.getElementById("threesixty"), {
      // image: props.image,
      width: 1000,
      height: 500,
      speed: 150,
      draggable: false,
      swipeable: false,
      keys: false,
      prev: document.querySelector(".prev"),
      next: document.querySelector(".next"),
      inverted: false,
    });

    document.getElementById("threesixty").style.cursor = "ew-resize";
  }, []);

  return (
    <div class="wrapper">
      <Grid
        md={12}
        xs={12}
        sm={12}
        container
        id="threesixty"
        class="i-360"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      ></Grid>

      <button id="prev" class="prev" style={{ cursor: "pointer" }}>
        prev
      </button>

      <button id="next" class="next" style={{ cursor: "pointer" }}>
        next
      </button>
    </div>
  );
};

export default Images360;
