import * as types from "../action.type";
export const getProductAction = (data) => {
  return {
    type: types.GETPRODUCT,
    data,
  };
};
export const getProductActionSuccess = (data) => {
  return {
    type: types.GETPRODUCT_SUCCESS,
    data,
  };
};

export const getProductActionError = (data) => {
  return {
    type: types.GETPRODUCT_ERROR,
    data,
  };
};

export const AddProductAction = (data) => {
  return {
    type: types.ADDPRODUCT,
    data,
  };
};
export const AddProductActionSuccess = (data) => {
  return {
    type: types.ADDPRODUCT_SUCCESS,
    data,
  };
};

export const AddProductActionError = (data) => {
  return {
    type: types.ADDPRODUCT_ERROR,
    data,
  };
};

export const UpdateProductAction = (data) => {
  return {
    type: types.UPDATEPRODUCT,
    data,
  };
};
export const UpdateProductActionSuccess = (data) => {
  return {
    type: types.UPDATEPRODUCT_SUCCESS,
    data,
  };
};

export const UpdateProductActionError = (data) => {
  return {
    type: types.UPDATEPRODUCT_ERROR,
    data,
  };
};

export const DeleteProductAction = (data) => {
  return {
    type: types.DELETEPRODUCT,
    data,
  };
};
export const DeleteProductActionSuccess = (data) => {
  return {
    type: types.DELETEPRODUCT_SUCCESS,
    data,
  };
};

export const DeleteProductActionError = (data) => {
  return {
    type: types.DELETEPRODUCT_ERROR,
    data,
  };
};

export const DeleteProductImageAction = (data) => {
  return {
    type: types.DELETEPRODUCT_IMAGE,
    data,
  };
};
export const DeleteProductImageActionSuccess = (data) => {
  return {
    type: types.DELETEPRODUCT_IMAGE_SUCCESS,
    data,
  };
};

export const DeleteProductImageActionError = (data) => {
  return {
    type: types.DELETEPRODUCT_IMAGE_ERROR,
    data,
  };
};

export const UpdateProductImageAction = (data) => {
  return {
    type: types.UPDATEPRODUCT_IMAGE,
    data,
  };
};
export const UpdateProductImageActionSuccess = (data) => {
  return {
    type: types.UPDATEPRODUCT_IMAGE_SUCCESS,
    data,
  };
};

export const UpdateProductImageActionError = (data) => {
  return {
    type: types.UPDATEPRODUCT_IMAGE_ERROR,
    data,
  };
};
