import { put, call } from "redux-saga/effects";
import {
  getproductService,
  addProductService,
  UpdateProductService,
  deleteProductService,
  deleteProductImageService,
  updateProductImageService,
} from "../service/productService";
import {
  getProductActionSuccess,
  AddProductActionSuccess,
  UpdateProductActionSuccess,
  DeleteProductActionSuccess,
  DeleteProductImageActionSuccess,
  UpdateProductImageActionSuccess,
} from "../action/productAction";
import * as types from "../action.type";
export function* getProductSaga(data) {
  const response = yield call(getproductService, data);
  if (response && !response.error) {
    yield put(getProductActionSuccess(response.data));
  } else {
    yield put({ type: types.GETPRODUCT_ERROR, response });
  }
}

export function* AddProductSaga(data) {
  const response = yield call(addProductService, data);
  if (response && !response.error) {
    yield put(AddProductActionSuccess(response.data));
  } else {
    yield put({ type: types.ADDPRODUCT_ERROR, response });
  }
}

export function* UpdateProductSaga(data) {
  const response = yield call(UpdateProductService, data);
  if (response && !response.error) {
    yield put(UpdateProductActionSuccess(response.data));
  } else {
    yield put({ type: types.UPDATEPRODUCT_ERROR, response });
  }
}
export function* deleteProductSaga(data) {
  const response = yield call(deleteProductService, data);
  if (response && !response.error) {
    yield put(DeleteProductActionSuccess(response.data));
  } else {
    yield put({ type: types.DELETEPRODUCT_ERROR, response });
  }
}

export function* deleteProductImageSaga(data) {
  const response = yield call(deleteProductImageService, data);
  if (response && !response.error) {
    yield put(DeleteProductImageActionSuccess(response.data));
  } else {
    yield put({ type: types.DELETEPRODUCT_IMAGE_ERROR, response });
  }
}

export function* UpdateProductImageSaga(data) {
  const response = yield call(updateProductImageService, data);
  if (response && !response.error) {
    yield put(UpdateProductImageActionSuccess(response.data));
  } else {
    yield put({ type: types.UPDATEPRODUCT_IMAGE_ERROR, response });
  }
}
