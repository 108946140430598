import { put, call } from "redux-saga/effects";
import { getcontactService } from "../service/contact.js";
import { getContactSuccess } from "../action/contactaction";
import * as types from "../action.type";

function* getContactSaga() {
  const response = yield call(getcontactService);
  if (response && !response.error) {
    yield put({ type: types.GETCONTACT_SUCCESS, response });
  } else {
    yield put({ getContactSuccess, response });
  }
}

export { getContactSaga };
