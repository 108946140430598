import * as types from "../action.type";
// add
export const addProductCat = (data) => {
  return {
    type: types.ADD_PRODUCT_CATEGORY,
    data,
  };
};
export const addProductCatSucess = (data) => {
  return {
    type: types.ADD_PRODUCT_CATEGORY_SUCCESS,
    data,
  };
};

export const addProductCatError = (data) => {
  return {
    type: types.ADD_PRODUCT_CATEGORY_ERROR,
    data,
  };
};
//get
export const getProductCat = (data) => {
  return {
    type: types.GET_PRODUCT_CATEGORY,
    data,
  };
};
export const getProductCatSuccess = (data) => {
  return {
    type: types.GET_PRODUCT_CATEGORY_SUCCESS,
    data,
  };
};

export const getProductCatError = (data) => {
  return {
    type: types.GET_PRODUCT_CATEGORY_ERROR,
    data,
  };
};
// update
export const updateProductCat = (data) => {
  return {
    type: types.UPDATE_PRODUCT_CATEGORY,
    data,
  };
};
export const updateProductCatSucess = (data) => {
  return {
    type: types.UPDATE_PRODUCT_CATEGORY_SUCCESS,
    data,
  };
};

export const updateProductCatError = (data) => {
  return {
    type: types.UPDATE_PRODUCT_CATEGORY_ERROR,
    data,
  };
};
// DELETE
export const deletProductCat = (data) => {
  return {
    type: types.DELETE_PRODUCT_CATEGORY,
    data,
  };
};
export const deletProductCatSucess = (data) => {
  return {
    type: types.DELETE_PRODUCT_CATEGORY_SUCCESS,
    data,
  };
};

export const deletProductCatError = (data) => {
  return {
    type: types.DELETE_PRODUCT_CATEGORY_ERROR,
    data,
  };
};
