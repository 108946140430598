import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Card from "./downloadCard";

import { getDownload } from "../../store/action/donwloadaction";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AllDownload = useSelector((state) => state.getDownload);
  const [params, setparams] = useState();

  useEffect(() => {
    let params = new URLSearchParams(window.location.search).get("download");
    setparams(params);
    dispatch(getDownload(params));
  }, [dispatch]);

  return (
    <Container maxWidth={false}>
      <Box mt={3}>
        <Grid md={12} xs={12} sm={12} item container spacing={3}>
          {params ? (
            <Grid item sm={12} md={12} xs={12}>
              <Card
                className={classes.productCard}
                downloadData={AllDownload && AllDownload.downloadFiles}
                params={params}
              />
            </Grid>
          ) : (
            <Typography
              style={{
                fontSize: 24,
                color: "#646464",
                fontWeight: 700,
                padding: 32,
              }}
            >
              Please Select Any Download Category
            </Typography>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductList;
