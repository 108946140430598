export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";
export const AUTH_CHECK_ERROR = "AUTH_CHECK_ERROR";

// update password
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

// job posting
export const ADD_VACANCY = "ADD_VACANCY";
export const ADD_VACANCY_SUCCESS = "ADD_VACANCY_SUCCESS";
export const ADD_VACANCY_ERROR = "ADD_VACANCY_ERROR";

export const UPDATE_VACANCY = "UPDATE_VACANCY";
export const UPDATE_VACANCY_SUCCESS = "UPDATE_VACANCY_SUCCESS";
export const UPDATE_VACANCY_ERROR = "UPDATE_VACANCY_ERROR";

export const DELETE_VACANCY = "DELETE_VACANCY";
export const DELETE_VACANCY_SUCCESS = "DELETE_VACANCY_SUCCESS";
export const DELETE_VACANCY_ERROR = "DELETE_VACANCY_ERROR";

export const GETVACANCY = "GETVACANCY";
export const GETVACANCY_SUCCESS = "GETVACANCY_SUCCESS";
export const GETVACANCY_ERROR = "GETVACANCY_ERROR";

// get application
export const GET_APLICATION = "GET_APLICATION";
export const GET_APLICATION_SUCCESS = "GET_APLICATION_SUCCESS";
export const GET_APLICATION_ERROR = "GET_APLICATION_ERROR";

// GET CONTACT
export const GETCONTACT = "GETCONTACT";
export const GETCONTACT_SUCCESS = "GETCONTACT_SUCCESS";
export const GETCONTACT_ERROR = "GETCONTACT_ERROR";
//NEWS *******
export const GET_NEWS_EVENTS = "GET_NEWS_EVENTS";
export const GET_NEWS_EVENTS_SUCCESS = "GET_NEWS_EVENTS_SUCCESS";
export const GET_NEWS_EVENTS_ERROR = "GET_NEWS_EVENTS_ERROR";

export const ADD_NEWS_EVENTS = "ADD_NEWS_EVENTS";
export const ADD_NEWS_EVENTS_SUCCESS = "ADD_NEWS_EVENTS_SUCCESS";
export const ADD_NEWS_EVENTS_ERROR = "ADD_NEWS_EVENTS_ERROR";

export const UPDATE_NEWS_EVENTS = "UPDATE_NEWS_EVENTS";
export const UPDATE_NEWS_EVENTS_SUCCESS = "UPDATE_NEWS_EVENTS_SUCCESS";
export const UPDATE_NEWS_EVENTS_ERROR = "UPDATE_NEWS_EVENTS_ERROR";

export const DELETE_NEWS_EVENTS = "DELETE_NEWS_EVENTS";
export const DELETE_NEWS_EVENTS_SUCCESS = "DELETE_NEWS_EVENTS_SUCCESS";
export const DELETE_NEWS_EVENTS_ERROR = "DELETE_NEWS_EVENTS_ERROR";

// PRODUCT CATE
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const ADD_PRODUCT_CATEGORY_ERROR = "ADD_PRODUCT_CATEGORY_ERROR";

export const UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
  "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_ERROR = "UPDATE_PRODUCT_CATEGORY_ERROR";

export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_ERROR = "DELETE_PRODUCT_CATEGORY_ERROR";

export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_ERROR = "GET_PRODUCT_CATEGORY_ERROR";

// ADD product

export const GETPRODUCT = "GETPRODUCT";
export const GETPRODUCT_SUCCESS = "GETPRODUCT_SUCCESS";
export const GETPRODUCT_ERROR = "GETPRODUCT_ERROR";

export const ADDPRODUCT = "ADDPRODUCT";
export const ADDPRODUCT_SUCCESS = "ADDPRODUCT_SUCCESS";
export const ADDPRODUCT_ERROR = "ADDPRODUCT_ERROR";

export const UPDATEPRODUCT = "UPDATEPRODUCT";
export const UPDATEPRODUCT_SUCCESS = "UPDATEPRODUCT_SUCCESS";
export const UPDATEPRODUCT_ERROR = "UPDATEPRODUCT_ERROR";

export const DELETEPRODUCT = "DELETEPRODUCT";
export const DELETEPRODUCT_SUCCESS = "DELETEPRODUCT_SUCCESS";
export const DELETEPRODUCT_ERROR = "DELETEPRODUCT_ERROR";

export const UPDATEPRODUCT_IMAGE = "UPDATEPRODUCT_IMAGE";
export const UPDATEPRODUCT_IMAGE_SUCCESS = "UPDATEPRODUCT_IMAGE_SUCCESS";
export const UPDATEPRODUCT_IMAGE_ERROR = "UPDATEPRODUCT_IMAGE_ERROR";

export const DELETEPRODUCT_IMAGE = "DELETEPRODUCT_IMAGE";
export const DELETEPRODUCT_IMAGE_SUCCESS = "DELETEPRODUCT_IMAGE_SUCCESS";
export const DELETEPRODUCT_IMAGE_ERROR = "DELETEPRODUCT_IMAGE_ERROR";

export const UPDATE_PRODUCT_IMAGE = "UPDATE_PRODUCT_IMAGE";
export const UPDATE_PRODUCT_IMAGE_SUCCESS = "UPDATE_PRODUCT_IMAGE_SUCCESS";
export const UPDATE_PRODUCT_IMAGE_ERROR = "UPDATE_PRODUCT_IMAGE_ERROR";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

// downloads
export const GETDOWNLOAD = "GETDOWNLOAD";
export const GETDOWNLOAD_SUCCESS = "GETDOWNLOAD_SUCCESS";
export const GETDOWNLOAD_ERROR = "GETDOWNLOAD_ERROR";

export const ADDDOWNLOAD = "ADDDOWNLOAD";
export const ADDDOWNLOAD_SUCCESS = "ADDDOWNLOAD_SUCCESS";
export const ADDDOWNLOAD_ERROR = "ADDDOWNLOAD_ERROR";

export const UPDATEDOWNLOAD = "UPDATEDOWNLOAD";
export const UPDATEDOWNLOAD_SUCCESS = "UPDATEDOWNLOAD_SUCCESS";
export const UPDATEDOWNLOAD_ERROR = "UPDATEDOWNLOAD_ERROR";

export const DELETEDOWNLOAD = "DELETEDOWNLOAD";
export const DELETEDOWNLOAD_SUCCESS = "DELETEDOWNLOAD_SUCCESS";
export const DELETEDOWNLOAD_ERROR = "DELETEDOWNLOAD_ERROR";
