import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import "../../../NewsEvents/form.css";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    minWidth: 800,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  submitButton: {
    marginTop: "43px",
    background: "#FD6A02",
    textTransform: "none",
    color: theme.palette.text.disabled,
    "&$buttonDisabled": {
      color: theme.palette.text.disabled,
      background: theme.palette.background.paper,
    },
    "&:hover": {
      background: "#FD6A02",
      color: "#fff",
    },
  },
  submitText: {
    color: "#fff",
    "&$buttonDisabled": {
      color: "#000",
    },
  },
  fileUpload: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#fff !important",
  },
}));
const LoginView = (props) => {
  let {
    values: { img, additionalImages, AdditionForm },
    handleSubmit,
    ImageFile,
    handleAdditionalImage,
    previousImageUpdateUrl,
    handleDeletImage,
    loader,
    close,
    handleAdditionalImagedelete,
  } = props;

  const classes = useStyles();
  const [threeSixtyimage, setthreeSixtyimage] = useState();
  const [addimagename, setaddimagename] = useState();
  const [previousFile, setpreviousFile] = useState();

  const imgChangeHandler = (obj) => (e) => {
    previousImageUpdateUrl(obj);
    var file = e.target.files;
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      setpreviousFile(obj);
      setthreeSixtyimage(e.target.files[0].name);
      ImageFile(file);
    }
  };
  const uploadimgChangeHandler = (e) => {
    var file = e.target.files;
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      ImageFile(file);
    }
  };
  const handleAdditionalimage = (obj) => (e) => {
    previousImageUpdateUrl(obj);
    var file = e.target.files;
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      setpreviousFile(obj);
      setaddimagename(e.target.files[0].name);
      handleAdditionalImage(e.target.files);
    }
  };
  const newAddImageUploadHandler = (e) => {
    var file = e.target.files;
    if (e.target.files[0] === null) {
      return null;
    } else if (file) {
      handleAdditionalImage(file);
    }
  };
  const handleImageDelete = (obj) => {
    handleDeletImage(obj);
  };
  const hanldeAddImageDelt = (obj) => {
    handleAdditionalImagedelete(obj);
  };

  return (
    <Grid md={12} xs={12} sm={12} item className={classes.root}>
      <form onSubmit={handleSubmit} style={{ padding: 48, width: 900 }}>
        {AdditionForm ? null : (
          <Grid md={12} style={{ display: "flex" }}>
            <Grid md={3} item>
              <Typography style={{ margin: "19px 0px" }}>
                Image:<span style={{ color: "tomato" }}>*</span>
              </Typography>
            </Grid>
            <Grid md={9} container>
              <TextField
                type="file"
                variant="outlined"
                required={img ? false : true}
                onChange={uploadimgChangeHandler}
                inputProps={{
                  accept: "image/png, image/jpg, image/jpeg",
                  id: "file",
                  multiple: true,
                }}
                name="avatar"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid
                md={12}
                container
                direction="row"
                style={{ display: "flex" }}
              >
                {img.map((obj) => (
                  <Grid md={3} container style={{ display: "block" }}>
                    <img
                      src={obj}
                      alt="img"
                      style={{
                        objectFit: "cover",
                        height: 130,
                        width: 100,
                      }}
                    />

                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      container
                      justify="space-betweem"
                    >
                      <Button component="label">
                        {previousFile === obj ? (
                          <Typography
                            style={{
                              whiteSpace: "nowrap",
                              width: "50px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {threeSixtyimage}
                          </Typography>
                        ) : (
                          <EditIcon className={classes.EditIcon} />
                        )}
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          multiple
                          style={{ display: "none" }}
                          onChange={imgChangeHandler(obj)}
                        />
                      </Button>

                      {img.length === 1 ? null : (
                        <IconButton
                          onClick={() => handleImageDelete(obj)}
                          style={{ padding: 0 }}
                        >
                          <DeleteIcon className={classes.EditIcon} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        {AdditionForm ? (
          <Grid md={12} item style={{ display: "flex" }}>
            <Grid md={3} item>
              <Typography style={{ margin: "19px 0px" }}>
                additionalImages:<span style={{ color: "tomato" }}>*</span>
              </Typography>
            </Grid>
            <Grid md={9} container>
              <TextField
                type="file"
                variant="outlined"
                required={additionalImages ? false : true}
                onChange={newAddImageUploadHandler}
                inputProps={{
                  accept: "image/png, image/jpg, image/jpeg",
                  id: "file",
                  multiple: true,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Grid md={12} item style={{ display: "flex" }}>
                {additionalImages.map((obj) => (
                  <Grid md={3} container style={{ display: "block" }}>
                    <img
                      src={obj}
                      alt="img"
                      style={{
                        objectFit: "cover",
                        height: 130,
                        width: 100,
                      }}
                    />

                    <Grid
                      md={12}
                      xs={12}
                      sm={12}
                      item
                      container
                      justify="space-betweem"
                    >
                      <Button component="label">
                        {previousFile === obj ? (
                          <Typography
                            style={{
                              whiteSpace: "nowrap",
                              width: "50px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {addimagename}
                          </Typography>
                        ) : (
                          <EditIcon className={classes.EditIcon} />
                        )}

                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleAdditionalimage(obj)}
                        />
                      </Button>

                      {additionalImages.length === 1 ? null : (
                        <IconButton
                          onClick={() => hanldeAddImageDelt(obj)}
                          style={{ padding: 0 }}
                        >
                          <DeleteIcon className={classes.EditIcon} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        <Grid md={12} container item justify="center" style={{ marginTop: 20 }}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            style={{ background: "#FD6A02", textTransform: "none" }}
          >
            <Typography style={{ color: "#fff" }}>Submit</Typography>
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            style={{
              background: "#FD6A02",
              textTransform: "none",
              marginLeft: 24,
            }}
            onClick={() => close()}
          >
            <Typography style={{ color: "#fff" }}>Cancel</Typography>
          </Button>
          {loader ? (
            <CircularProgress style={{ color: "#FD6A02", marginLeft: 24 }} />
          ) : null}
        </Grid>
      </form>
    </Grid>
  );
};

export default LoginView;
