import * as types from "../action.type";
export const addNews = (data) => {
  return {
    type: types.ADD_NEWS_EVENTS,
    data,
  };
};
export const addNewsSucess = (data) => {
  return {
    type: types.ADD_NEWS_EVENTS_SUCCESS,
    data,
  };
};

export const addNewsError = (data) => {
  return {
    type: types.ADD_NEWS_EVENTS_ERROR,
    data,
  };
};
//get News
export const getNews = (data) => {
  return {
    type: types.GET_NEWS_EVENTS,
    data,
  };
};
export const getNewsSuccess = (data) => {
  return {
    type: types.GET_NEWS_EVENTS_SUCCESS,
    data,
  };
};

export const getNewsError = (data) => {
  return {
    type: types.GET_NEWS_EVENTS_ERROR,
    data,
  };
};

// update News
export const updateNews = (data) => {
  return {
    type: types.UPDATE_NEWS_EVENTS,
    data,
  };
};
export const updateNewsSuccess = (data) => {
  return {
    type: types.UPDATE_NEWS_EVENTS_SUCCESS,
    data,
  };
};

export const updateNewsError = (data) => {
  return {
    type: types.UPDATE_NEWS_EVENTS_ERROR,
    data,
  };
};
// DELETENEWS
export const deleteNews = (data) => {
  return {
    type: types.DELETE_NEWS_EVENTS,
    data,
  };
};
export const deleteNewsSuccess = (data) => {
  return {
    type: types.DELETE_NEWS_EVENTS_SUCCESS,
    data,
  };
};

export const deleteNewsError = (data) => {
  return {
    type: types.DELETE_NEWS_EVENTS_ERROR,
    data,
  };
};
