import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Typography, Grid, Button } from "@material-ui/core";
import Form from "./form";
import { validation } from "./validation";
import {
  addvacancy,
  updateVacancy,
} from "../../../../store/action/careeraction";

const AddVacancy = (props) => {
  const { close, onClose, open, Update_Previous_Data, isActive } = props;

  const [JobDesc, SetJobDesc] = useState();

  const dispatch = useDispatch();

  const addVacancy = useSelector((state) => state.addVacancyReducer);
  const update = useSelector((state) => state.updateVacancyReducer);

  const initialval = {
    Title: Update_Previous_Data ? Update_Previous_Data.Jobtitle : "",
    shortDesc: Update_Previous_Data
      ? Update_Previous_Data.shortDescription
      : "",
    city: Update_Previous_Data ? Update_Previous_Data.city : "",
    country: Update_Previous_Data ? Update_Previous_Data.country : "",
    JobDescription: Update_Previous_Data ? Update_Previous_Data.JobDesc : "",
    Active: Update_Previous_Data ? Update_Previous_Data.isActive : "",
  };

  const handleFile = (url) => {
    SetJobDesc(url);
  };
  const [loader, setloader] = useState(false);
  const ShowLoader = () => {
    setloader(true);
  };

  const handleSubmit = (val) => {
    ShowLoader();
    if (!Update_Previous_Data) {
      dispatch(
        addvacancy({
          Jobtitle: val.Title,
          shortDescription: val.shortDesc,
          country: val.country,
          city: val.city,
          JobDesc: JobDesc,
          isActive: isActive ? isActive : true,
        })
      );
    }

    if (Update_Previous_Data) {
      dispatch(
        updateVacancy({
          id: Update_Previous_Data._id,
          Jobtitle: val.Title ? val.Title : Update_Previous_Data.Jobtitle,
          shortDescription: val.shortDesc
            ? val.shortDesc
            : Update_Previous_Data.shortDescription,
          country: val.country ? val.country : Update_Previous_Data.country,
          city: val.city ? val.city : Update_Previous_Data.city,
          JobDesc: JobDesc ? JobDesc : Update_Previous_Data.JobDesc,
          isActive: isActive ? isActive : Update_Previous_Data.isActive,
        })
      );
    }
  };
  const handleClose = () => {
    window.location.reload();
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="false"
      style={{ marginLeft: 210 }}
    >
      {" "}
      {addVacancy.success || update.success ? (
        <Grid
          container
          justify="center"
          style={{ padding: 100, minWidth: 900, display: "grid" }}
        >
          <Typography style={{ textAlign: "center" }}>
            Vacancy {update.success ? "update" : "Add"} Successfully
          </Typography>
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClose}
            style={{
              background: "#FD6A02",
              textTransform: "none",
              marginTop: 24,
            }}
          >
            <Typography style={{ color: "#fff" }}>Close</Typography>
          </Button>
        </Grid>
      ) : (
        <Formik
          initialValues={initialval}
          close={close}
          onSubmit={handleSubmit}
          validationSchema={validation}
        >
          {(props) => (
            <Form
              {...props}
              JOb_descFile={(url) => handleFile(url)}
              loader={loader}
              close={onClose}
            />
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default AddVacancy;
