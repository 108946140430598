import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "left",
  },
  boldText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  card: {
    cursor: "pointer",
    marginRight: 40,
    padding: 20,
    display: "block",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
    },
  },
  linkedInProfile: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    textAlign: "left",
    color: "#0000EE",
  },
}));
const Contact = (props) => {
  const { data } = props;

  const classes = useStyles();
  return (
    <Box
      bgcolor="white"
      alignItems="center"
      justifyContent="center"
      boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
      fontSize="h6.fontSize"
      borderRadius="8px"
      marginBottom="30px"
      className={classes.card}
      key={data.fullName}
    >
      <Grid md={12} sm={12} xs={12} item container justify="space-between">
        {data.others ? (
          <Typography> Others | {data.User_choice}</Typography>
        ) : (
          <Typography> {data.jobTitle}</Typography>
        )}
        <Typography>
          Applied On:
          <span style={{ fontWeight: 700 }}>
            {data?.Date ? new Date(data?.Date)?.toLocaleDateString() : null}
          </span>
        </Typography>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 12 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Name: </span>
          </Typography>
        </Grid>
        <Grid md={4} item>
          <Typography className={classes.text}>{data.name}</Typography>
        </Grid>
        <Grid md={3}>
          {data.resumeUrl ? (
            <Typography className={classes.text}>
              <a href={data.resumeUrl} target="_blank" rel="noreferrer">
                <span
                  style={{ color: "#FD6A02", fontSize: 14, fontWeight: "bold" }}
                >
                  VIEW RESUME
                </span>
              </a>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Phone: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>{data.phone}</Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Email: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <a href={`mailto:${data.email}`} style={{ color: "#000" }}>
              {" "}
              {data.email}
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Experience:</span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <Typography className={classes.text}>{data.Experience}</Typography>
          </Typography>
        </Grid>
      </Grid>

      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> High. Qualification:</span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <Typography className={classes.text}>
              {data.highestQualification}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Linkedin: </span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography className={classes.linkedInProfile}>
            <a href={`${data.linkedInProfile}`} style={{ color: "#000" }}>
              {" "}
              {data.linkedInProfile}
            </a>
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid md={12} item style={{ display: "flex", marginTop: 8 }}>
        <Grid md={5} item>
          <Typography className={classes.text}>
            <span className={classes.boldText}> Skills:</span>
          </Typography>
        </Grid>
        <Grid md={5} item>
          <Typography
            className={classes.text}
            style={{ display: "flex", marginTop: 8 }}
          >
            {Array.isArray(data.skills) &&
              data.skills.map((obj, i) => (
                <span style={{ display: "flex" }}>
                  <Typography>
                    {obj}

                    {i + 1 === data.skills.length ? "" : ","}
                  </Typography>
                </span>
              ))}
          </Typography>
        </Grid>
      </Grid> */}
    </Box>
  );
};
export default Contact;
