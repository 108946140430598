import { takeLatest } from "redux-saga/effects";
import * as types from "../action.type";
import {
  signUpSaga,
  loginSaga,
  authCheckSaga,
  updatePasswordSaga,
  logoutService,
} from "./signup.saga";
import { getContactSaga } from "./contactsaga";
import {
  getVacancySaga,
  AddVacancySaga,
  getApplicationSaga,
  updateVacancySaga,
  deleteVacancySaga,
} from "./careersaga";
import {
  AddNewsSaga,
  getNewsSaga,
  updateNewsSaga,
  deleteNewsSaga,
} from "./news_eventssaga";

import {
  addProductCatSAGA,
  getProductCatSAGA,
  updateProductCatSaga,
  deleteProductCat,
} from "./productCatsaga";

import {
  getProductSaga,
  AddProductSaga,
  deleteProductSaga,
  UpdateProductSaga,
  deleteProductImageSaga,
  UpdateProductImageSaga,
} from "./productSaga";

import {
  addDownloadSaga,
  getDownloadSaga,
  updateDownloadSaga,
  deleteDownloadSaga,
} from "./downloadsaga";
function* watcher() {
  yield takeLatest(types.SIGN_UP, signUpSaga);
  yield takeLatest(types.LOGIN, loginSaga);
  yield takeLatest(types.AUTH_CHECK, authCheckSaga);
  yield takeLatest(types.UPDATE_PASSWORD, updatePasswordSaga);
  yield takeLatest(types.LOGOUT, logoutService);
  yield takeLatest(types.ADD_VACANCY, AddVacancySaga);
  yield takeLatest(types.GETVACANCY, getVacancySaga);
  yield takeLatest(types.UPDATE_VACANCY, updateVacancySaga);
  yield takeLatest(types.DELETE_VACANCY, deleteVacancySaga);

  yield takeLatest(types.GET_APLICATION, getApplicationSaga);

  yield takeLatest(types.GETCONTACT, getContactSaga);

  yield takeLatest(types.ADD_NEWS_EVENTS, AddNewsSaga);
  yield takeLatest(types.GET_NEWS_EVENTS, getNewsSaga);
  yield takeLatest(types.DELETE_NEWS_EVENTS, deleteNewsSaga);
  yield takeLatest(types.UPDATE_NEWS_EVENTS, updateNewsSaga);

  // productCat

  yield takeLatest(types.ADD_PRODUCT_CATEGORY, addProductCatSAGA);
  yield takeLatest(types.GET_PRODUCT_CATEGORY, getProductCatSAGA);
  yield takeLatest(types.UPDATE_PRODUCT_CATEGORY, updateProductCatSaga);
  yield takeLatest(types.DELETE_PRODUCT_CATEGORY, deleteProductCat);

  yield takeLatest(types.GETPRODUCT, getProductSaga);
  yield takeLatest(types.ADDPRODUCT, AddProductSaga);
  yield takeLatest(types.UPDATEPRODUCT, UpdateProductSaga);
  yield takeLatest(types.DELETEPRODUCT, deleteProductSaga);
  yield takeLatest(types.DELETEPRODUCT_IMAGE, deleteProductImageSaga);
  yield takeLatest(types.UPDATEPRODUCT_IMAGE, UpdateProductImageSaga);

  yield takeLatest(types.ADDDOWNLOAD, addDownloadSaga);
  yield takeLatest(types.UPDATEDOWNLOAD, updateDownloadSaga);
  yield takeLatest(types.DELETEDOWNLOAD, deleteDownloadSaga);
  yield takeLatest(types.GETDOWNLOAD, getDownloadSaga);
}

export default watcher;
