import React, { useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useDispatch } from "react-redux";
import DashboardLayout from "src/layouts/DashboardLayout";
import AllImages from "./views/360Images";
import MainLayout from "src/layouts/MainLayout";
import AccountView from "src/views/account/AccountView";
import Intro from "src/views/overview";
import About from "src/views/overview/about";
import Contact from "src/views/contact";
import Career from "src/views/careers/careers";
import Download from "src/views/downloads/download";
import AllApplications from "src/views/careers/allApplications";
import AllVacancy from "src/views/careers/allVacancy";
import DashboardView from "src/views/reports/DashboardView";
import News from "src/views/NewsEvents";
import AddNews from "src/views/NewsEvents/NewsEvents";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import ProductListView from "src/views/productCategory/ProductListView";
import ProductsList from "src/views/AllProducts";
import ProductDetail from "src/views/AllProducts/productDetail";
import AddProductCategory from "src/views/productCategory/ProductListView/AddProductCat";
import { authCheck } from "./store/action/signupaction";

const MyApp = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheck());
  }, [dispatch]);
  // let isLoggedIn = false;
  // const authStatus = useSelector((state) => state.authCheckReducer);

  // if (authStatus.success) {
  //   isLoggedIn = true;
  // }

  const routes = useRoutes([
    {
      path: "app",
      element: <DashboardLayout />,
      children: [
        { path: "account", element: <AccountView /> },
        { path: "dashboard", element: <DashboardView /> },
        { path: "products", element: <ProductListView /> },
        { path: "products/add", element: <AddProductCategory /> },
        { path: "intro", element: <Intro /> },
        { path: "about", element: <About /> },
        { path: "News", element: <News /> },
        { path: "News/add", element: <AddNews /> },
        { path: "careers", element: <Career /> },
        { path: "Allproducts", element: <ProductsList /> },
        { path: "Allproducts/detailPage", element: <ProductDetail /> },
        { path: "careers/applications", element: <AllApplications /> },
        { path: "careers/vacancy", element: <AllVacancy /> },
        { path: "contact", element: <Contact /> },
        { path: "downloads", element: <Download /> },
        { path: "360", element: <AllImages /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LoginView /> },

        { path: "404", element: <NotFoundView /> },
        { path: "/", element: <Navigate to="/app/dashboard" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);
  return routes;
};

export default MyApp;
