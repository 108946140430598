import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ProductUpdate from "../AddProductForm/AddProduct";
import { DeleteProductAction } from "../../../store/action/productAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  dialog: {
    position: "absolute",
    height: 200,
    padding: 24,
    alignItems: "center",
  },
}));

const ProductCard = ({ className, product, News, params, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const deleteProduct = useSelector((state) => state.DeleteProductReducer);

  const handleDelte = (id) => {
    setdelete_product(true);
  };

  const [open, setopen] = useState(false);
  const [delete_product, setdelete_product] = useState(false);

  const handleOpen = (data) => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleProductDetail = (id, productCategory) => {
    window.location.replace(
      `Allproducts/detailPage?product=${productCategory}&id=${id}&category=${params?.[0]}`
    );
  };
  const hanldeDeleteProduct = (id) => {
    dispatch(DeleteProductAction(id));

    window.location.reload();
  };
  const hanldedelete_CancelProduct = () => {
    setdelete_product(false);
  };
  console.log(`product`, params);
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent
        style={{ padding: 0, cursor: "pointer" }}
        onClick={() =>
          handleProductDetail(product._id, product.productCategory)
        }
      >
        <Grid style={{ height: 200, position: "relative" }}>
          <img
            alt="productImage"
            src={product ? product.featuredImage : null}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Grid>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2} style={{ display: "flex" }}>
        <Grid container justify="space-between" spacing={2}>
          <Typography align="center" color="textPrimary" variant="body1">
            {product ? product.title : null}
          </Typography>
        </Grid>
        <Box style={{ marginRight: 20 }}>
          <IconButton
            onClick={() => handleOpen(product)}
            style={{ padding: 0 }}
          >
            <EditIcon fontSize="small" style={{ color: "#000000" }} />
          </IconButton>
        </Box>
        <Box>
          <IconButton style={{ padding: 0 }} onClick={() => handleDelte()}>
            <DeleteIcon fontSize="small" style={{ color: "#000000" }} />
          </IconButton>
        </Box>
        <Dialog
          open={delete_product}
          onClose={hanldedelete_CancelProduct}
          style={{ position: "absolute", top: 0 }}
          classes={{
            paper: classes.dialog,
          }}
        >
          <Typography style={{ marginTop: 50 }}>
            Do you want ,If yes{" "}
            <Button
              onClick={() => hanldeDeleteProduct(product ? product._id : null)}
            >
              <span style={{ color: "tomato" }}>Confirm</span>
            </Button>
            else{" "}
            <Button onClick={() => hanldedelete_CancelProduct()}>
              <span style={{ color: "green" }}>Cancel</span>
            </Button>
          </Typography>
        </Dialog>
      </Box>

      <ProductUpdate open={open} onClose={handleClose} ProductData={product} />
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
};

export default ProductCard;
