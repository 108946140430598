import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Dialog, Typography, Button } from "@material-ui/core";
import Form from "./form";
import { addNews, updateNews } from "../../store/action/news_eventsaction";

const NewsEvents = (props) => {
  // const [submit, setsubmit] = useState(false);
  const [ImageFile, setImageFile] = useState();

  const dispatch = useDispatch();

  const update_News = useSelector((state) => state.updateNewsReducer);
  const add_News = useSelector((state) => state.addNewsReducer);
  const { close, onClose, open, NewsData } = props;

  const handleFile = (url) => {
    setImageFile(url);
  };

  const initialval = {
    title: NewsData && NewsData.title ? NewsData && NewsData.title : "",
    img: NewsData && NewsData.imgUrl ? NewsData && NewsData.imgUrl : "",
    type: NewsData && NewsData.type ? NewsData && NewsData.type : "",
    shortDesc:
      NewsData && NewsData.shortDesc ? NewsData && NewsData.shortDesc : "",
    // type: NewsData && NewsData.tags ? NewsData && NewsData.tags : "",
    longDesc:
      NewsData && NewsData.longDesc ? NewsData && NewsData.longDesc : "",
    date: NewsData && NewsData.date ? NewsData && NewsData.date : "",
    time: NewsData && NewsData.time ? NewsData && NewsData.time : "",
    place: NewsData && NewsData.place ? NewsData && NewsData.place : "",
    link: NewsData && NewsData.link ? NewsData && NewsData.link : "",
    tags: NewsData && NewsData.tags ? NewsData && NewsData.tags : "",
  };
  const [loader, setloader] = useState(false);

  const handleClose = () => {
    onClose();
    window.location.reload();
  };
  const ShowLoader = () => {
    setloader(true);
  };
  const handleSubmit = (val) => {
    ShowLoader();

    if (!NewsData) {
      dispatch(
        addNews({
          title: val.title,
          imgUrl: ImageFile,
          type: val.type,
          shortDesc: val.shortDesc,
          longDesc: val.longDesc,
          tags: val.tags,
          link: val.link,
          place: val.place,
          date: val.date,
          time: val.time,
        })
      );
    }

    if (NewsData) {
      dispatch(
        updateNews({
          id: NewsData && NewsData._id,
          title: val.title ? val.title : NewsData && NewsData.title,
          imgUrl: ImageFile ? ImageFile : NewsData && NewsData.imgUrl,

          type: val.type ? val.type : NewsData && NewsData.type,

          shortDesc: val.shortDesc
            ? val.shortDesc
            : NewsData && NewsData.shortDesc,
          longDesc: val.longDesc ? val.longDesc : NewsData && NewsData.longDesc,

          tags: val.tags ? val.tags : NewsData && NewsData.tags,

          link: val.link ? val.link : NewsData && NewsData.link,
          place: val.place ? val.place : NewsData && NewsData.place,
          date: val.date ? val.date : NewsData && NewsData.date,
          time: val.date ? val.date : NewsData && NewsData.time,
        })
      );
    }

    // setsubmit(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="false"
      style={{ marginLeft: 210 }}
    >
      {add_News.success || update_News.success ? (
        <Grid
          container
          justify="center"
          style={{ padding: 100, minWidth: 900, display: "grid" }}
        >
          <Typography style={{ textAlign: "center" }}>
            News {update_News.success ? "update" : "Add"} Successfully
          </Typography>
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClose}
            style={{
              background: "#FD6A02",
              textTransform: "none",
              marginTop: 24,
            }}
          >
            <Typography style={{ color: "#fff" }}>Close</Typography>
          </Button>
        </Grid>
      ) : (
        <span>
          <Typography style={{ margin: 20 }}>
            All <span style={{ color: "tomato" }}>*</span> fields are required
          </Typography>
          <Formik
            initialValues={initialval}
            close={close}
            onSubmit={handleSubmit}
            // validationSchema={validation}
          >
            {(props) => (
              <Form
                {...props}
                ImageFile={(url) => handleFile(url)}
                loader={loader}
                close={onClose}
              />
            )}
          </Formik>
        </span>
      )}
    </Dialog>
  );
};

export default NewsEvents;
