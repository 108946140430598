import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";
export const getproductService = (req) => {
  return axios
    .get(`${REACT_APP_API_URL}/products/getproduct?productCategory=${req.data}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const addProductService = (req) => {
  let productData = req.data;
  let data = new FormData();

  for (let i = 0; i < productData.img.length; i++) {
    data.append("imgUrl", productData.img[i]);
  }
  for (let i = 0; i < productData.additionalImages.length; i++) {
    data.append("additionalImages", productData.additionalImages[i]);
  }

  data.append("title", productData.name);
  data.append("features", productData.Features);
  data.append("shortDesc", productData.shortdescription);
  data.append("longDesc", productData.longDescription);
  data.append("comment", productData.comment);
  data.append("productCategory", productData.productCategory);
  data.append("productSubCategory", productData.subCategory);
  data.append("imgUrl", productData.img);
  data.append("backgroundImage", productData.backgroundiamge);
  data.append("featuredImage", productData.featuredImage);
  data.append("dataSheet", productData.datasheet);
  data.append("specifications", JSON.stringify(productData.specifications));

  return (
    axios
      // .post(`${REACT_APP_API_URL}/products/addProduct`, data)
      .post(`${REACT_APP_API_URL}/products/addProduct`, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      })
  );
};

export const UpdateProductService = (req) => {
  let productData = req.data;

  let data = new FormData();
  if (productData.img) {
    for (let i = 0; i < productData.img.length; i++) {
      data.append("imgUrl", productData.img[i]);
    }
  }
  if (productData.additionalImages) {
    for (let i = 0; i < productData.additionalImages.length; i++) {
      data.append("additionalImages", productData.additionalImages[i]);
    }
  }

  data.append("title", productData.name);
  data.append("features", productData.Features);
  data.append("featuredImage", productData.featuredImage);
  data.append("shortDesc", productData.shortdescription);
  data.append("longDesc", productData.longDescription);
  data.append("comment", productData.comment);
  data.append("productCategory", productData.productCategory);
  data.append("productSubCategory", productData.subCategory);
  data.append("backgroundImage", productData.backgroundiamge);
  data.append("dataSheet", productData.datasheet);
  data.append("specifications", JSON.stringify(productData.specifications));

  return axios
    .post(`${REACT_APP_API_URL}/products/updateProduct?id=${req.data.id}`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProductService = (req) => {
  return axios
    .delete(`${REACT_APP_API_URL}/products/deleteProduct?id=${req.data}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteProductImageService = (req) => {
  return axios
    .post(`${REACT_APP_API_URL}/products/deleteImage`, req.data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateProductImageService = (req) => {
  let productData = req.data;

  let data = new FormData();
  if (productData.img) {
    for (let i = 0; i < productData.img.length; i++) {
      data.append("imgUrl", productData.img[i]);
    }
  }
  if (productData.additionalImages) {
    for (let i = 0; i < productData.additionalImages.length; i++) {
      data.append("additionalImages", productData.additionalImages[i]);
    }
  }
  data.append("image", productData.image);

  return axios
    .post(`${REACT_APP_API_URL}/products/updateImage?id=${req.data.id}`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
