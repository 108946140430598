import * as types from "../action.type";
export const getProductReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.GETPRODUCT_SUCCESS:
      return response;
    case types.GETPRODUCT_ERROR:
      return state;
    default:
      return state;
  }
};

export const addProductReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.ADDPRODUCT_SUCCESS:
      return response;
    case types.ADDPRODUCT_ERROR:
      return state;
    default:
      return state;
  }
};

export const UpdateProductReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.UPDATEPRODUCT_SUCCESS:
      return response;
    case types.UPDATEPRODUCT_ERROR:
      return state;
    default:
      return state;
  }
};

export const DeleteProductReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.DELETEPRODUCT_SUCCESS:
      return response;
    case types.DELETEPRODUCT_ERROR:
      return state;
    default:
      return state;
  }
};

export const DeleteProductImageReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.DELETEPRODUCT_IMAGE_SUCCESS:
      return response;
    case types.DELETEPRODUCT_IMAGE_ERROR:
      return state;
    default:
      return state;
  }
};

export const UpdateProductImageReducer = (state = {}, action) => {
  const response = action.data;

  switch (action.type) {
    case types.UPDATEPRODUCT_IMAGE_SUCCESS:
      return response;
    case types.UPDATEPRODUCT_IMAGE_ERROR:
      return state;
    default:
      return state;
  }
};
