import * as types from "../action.type";
// get all opening
export const getAllVacancy = (data) => {
  return {
    type: types.GETVACANCY,
    data,
  };
};
export const getAllVacancySuccess = (data) => {
  return {
    type: types.GETVACANCY_SUCCESS,
    data,
  };
};

export const getAllVacancyError = (data) => {
  return {
    type: types.GETVACANCY_ERROR,
    data,
  };
};
// ADD vacancy
export const addvacancy = (data) => {
  return {
    type: types.ADD_VACANCY,
    data,
  };
};
export const addvacancySucess = (data) => {
  return {
    type: types.ADD_VACANCY_SUCCESS,
    data,
  };
};

export const addvacancyError = (data) => {
  return {
    type: types.ADD_VACANCY_ERROR,
    data,
  };
};
// update vacancy
export const updateVacancy = (data) => {
  return {
    type: types.UPDATE_VACANCY,
    data,
  };
};
export const updateVacancySucess = (data) => {
  return {
    type: types.UPDATE_VACANCY_SUCCESS,
    data,
  };
};

export const updateVacancyError = (data) => {
  return {
    type: types.UPDATE_VACANCY_ERROR,
    data,
  };
};
// delete vacancy
export const deleteVacancy = (data) => {
  return {
    type: types.DELETE_VACANCY,
    data,
  };
};
export const deleteVacancySucess = (data) => {
  return {
    type: types.DELETE_VACANCY_SUCCESS,
    data,
  };
};

export const deleteVacancyError = (data) => {
  return {
    type: types.DELETE_VACANCY_ERROR,
    data,
  };
};

// GET ALL APPLICATIONS
export const getAllApplication = (data) => {
  return {
    type: types.GET_APLICATION,
    data,
  };
};
export const getAllApplicationSuccess = (data) => {
  return {
    type: types.GET_APLICATION_SUCCESS,
    data,
  };
};

export const getAllApplicationError = (data) => {
  return {
    type: types.GET_APLICATION_ERROR,
    data,
  };
};
