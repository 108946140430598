import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";
export const getcontactService = () => {
  return axios
    .get(`${REACT_APP_API_URL}/contact/getContact`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });
};
