import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Typography,
  Grid,
  Button,
  DialogContent,
} from "@material-ui/core";
import Form from "./form";
import {
  addProductCat,
  updateProductCat,
} from "../../../store/action/productcategory";

const AddProductCategory = (props) => {
  const [ImageFile, setImageFile] = useState();
  const [iconImagefile, seticonImagefile] = useState();
  const dispatch = useDispatch();

  const update_product_cat = useSelector((state) => state.updateProductCat);

  const add_product_cat = useSelector((state) => state.addProductCat);

  const { close, onClose, open, ProductData } = props;

  const handleFile = (url) => {
    setImageFile(url);
  };
  const handleIconImage = (url) => {
    seticonImagefile(url);
  };
  const [loader, setloader] = useState(false);
  const ShowLoader = () => {
    setloader(true);
  };

  const initialval = {
    title: ProductData ? ProductData.title : null,
    img: ProductData ? ProductData.imgUrl : "",
    iconImage: ProductData ? ProductData.iconImage : "",
    shortDesc: ProductData ? ProductData.shortDesc : null,
    subCategory: ProductData ? ProductData.subCategory : null,
  };
  const handleSubmit = (val) => {
    ShowLoader();
    if (!ProductData) {
      dispatch(
        addProductCat({
          title: val.title,
          imgUrl: ImageFile,
          iconImage: iconImagefile,
          shortDesc: val.shortDesc,
          subCategory: val.subCategory,
        })
      );
    }
    if (ProductData) {
      dispatch(
        updateProductCat({
          id: ProductData && ProductData._id,
          title: val.title ? val.title : ProductData && ProductData.title,
          shortDesc: val.shortDesc
            ? val.shortDesc
            : ProductData && ProductData.shortDesc,
          iconImage: iconImagefile,
          subCategory: val.subCategory
            ? val.subCategory
            : ProductData && ProductData.subCategory,
          imgUrl: ImageFile,
        })
      );
    }

    if (add_product_cat.success) {
      onClose();
    }
  };
  const handleClose = () => {
    onClose();
    window.location.reload();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogContent style={{ padding: "32px 32px 50px 32px" }}>
        {add_product_cat.success || update_product_cat.success ? null : (
          <Typography style={{ margin: 20 }}>
            All <span style={{ color: "tomato" }}>*</span> fields are required
          </Typography>
        )}
        {add_product_cat.success || update_product_cat.success ? (
          <Grid
            container
            justify="center"
            style={{ padding: 100, minWidth: 900, display: "grid" }}
          >
            <Typography style={{ textAlign: "center" }}>
              Product {update_product_cat.success ? "update" : "Add"}{" "}
              Successfully
            </Typography>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={handleClose}
              style={{
                background: "#FD6A02",
                textTransform: "none",
                marginTop: 24,
              }}
            >
              <Typography style={{ color: "#fff" }}>Close</Typography>
            </Button>
          </Grid>
        ) : (
          <Formik
            initialValues={initialval}
            close={close}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form
                {...props}
                ImageFile={(url) => handleFile(url)}
                iconImagefile={(url) => handleIconImage(url)}
                response={add_product_cat}
                loader={loader}
              />
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddProductCategory;
