import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  Dialog,
  Fade,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { getAllApplication } from "../../../store/action/careeraction";
import { getAllVacancy } from "../../../store/action/careeraction";
const Filter = (props) => {
  const dispatch = useDispatch();
  const { handleFilter_value } = props;
  // const allApp = useSelector((state) => state.getAllApplication);
  const AllVacancy = useSelector((state) => state.getAllVacancyReducer);

  useEffect(() => {
    dispatch(getAllApplication());
    dispatch(getAllVacancy());
  }, [dispatch]);

  const [filterValue, setfilterValue] = React.useState("");
  const [open, setopen] = useState(false);

  const handleChange = (val) => {
    setfilterValue(typeof val === "object" ? val.Jobtitle : val);
    handleFilter_value(val);
    setopen(false);
  };

  const handleClose = () => {
    setopen(false);
  };
  const handleClick = () => {
    setopen(true);
  };

  const useStyle = makeStyles((theme) => ({
    root: {
      backgroundColor: "transparent",
    },
    paper: {
      position: "absolute",
      top: 100,
      right: 0,
      padding: 12,
    },
    buttons: {
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 6,
      textTransform: "none",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    buttonText: {
      fontSize: theme.typography.body1.fontSize,
      color: "#000",

      width: "max-content",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.caption.fontSize,
      },
    },
    button: {
      borderRadius: "4px",
      height: 35,
      display: "flex",
      textTransform: "none",
      border: "2px solid #FD6A02",
      padding: "4px 8px  ",
      // width: "100%",
      [theme.breakpoints.down("xs")]: {
        padding: 5,
      },
    },

    yearText: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.secondary.dark,
    },
    ExpandMoreIcon: {
      color: theme.palette.secondary.main,
    },

    buttonTextTitle: {
      fontSize: theme.typography.body2.fontSize,
      color: "#FD6A02",

      width: "max-content",
    },
    ButtonGrid: {
      display: "flex",
    },
    selectedVal: {
      fontSize: theme.typography.body2.fontSize,
      color: "#fff",
      fontWeight: 700,

      width: "max-content",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.caption.fontSize,
      },
    },
    close: {
      color: theme.palette.secondary.dark,
      transition: "0.3s",
      paddingRight: 32,
      zIndex: 1,
      "&:hover": {
        color: " #bbb",
        textDecoration: "none",
        cursor: "pointer",
      },
      "&:focus": {
        color: " #bbb",
        textDecoration: "none",
        cursor: "pointer",
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  }));

  const classes = useStyle();

  return (
    <React.Fragment>
      <Grid item>
        <Grid className={classes.ButtonGrid}>
          <Button
            variant="outlined"
            margin="dense"
            className={classes.button}
            onClick={handleClick}
            endIcon={
              <ExpandMoreOutlinedIcon
                className={classes.ExpandMoreIcon}
                style={{
                  color: filterValue ? "#fff" : "#FD6A02",
                }}
              />
            }
            style={{ background: filterValue ? "#FD6A02" : "#fff" }}
          >
            {filterValue ? (
              <Typography className={classes.selectedVal}>
                {filterValue}
              </Typography>
            ) : (
              <Typography className={classes.buttonTextTitle}>
                Filter
              </Typography>
            )}
          </Button>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{ classes: { root: classes.paper } }}
        >
          <Fade in={open}>
            <div>
              <Button
                size="small"
                variant="text"
                className={classes.buttons}
                onClick={() => handleChange("All")}
              >
                <Typography className={classes.buttonText}>All</Typography>
              </Button>
              {AllVacancy &&
                AllVacancy.Jobs &&
                AllVacancy.Jobs.map((obj, i) => (
                  <Grid md={12} container item key={i}>
                    <Button
                      size="small"
                      variant="text"
                      className={classes.buttons}
                      onClick={() => handleChange(obj)}
                    >
                      <Typography className={classes.buttonText}>
                        {obj.Jobtitle}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              <Button
                size="small"
                variant="text"
                className={classes.buttons}
                onClick={() => handleChange("Others")}
              >
                <Typography className={classes.buttonText}>Others</Typography>
              </Button>
            </div>
          </Fade>
        </Dialog>
      </Grid>
      {/* </Grid> */}
    </React.Fragment>
  );
};

export default Filter;
