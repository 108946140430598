import * as types from "../action.type";

export const getContact = (data) => {
  return {
    type: types.GETCONTACT,
  };
};

export const getContactSuccess = (data) => {
  return {
    type: types.GETCONTACT_SUCCESS,
    data,
  };
};

export const getContactError = (data) => {
  return {
    type: types.GETCONTACT_ERROR,
    data,
  };
};
