import * as Yup from "yup";
export const validation = Yup.object().shape({
  Title: Yup.string("Enter a Title")
    .required("Title is required")
    .max(100, "not more than 100"),
  shortDesc: Yup.string("Enter a shortDesc").max(100, "not more than 100"),
  city: Yup.string("Enter a city")
    .max(100, "not more than 100")
    .min(3, "min 3  "),

  country: Yup.string("Enter a country").max(100, "not more than 100"),
});
