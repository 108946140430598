import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";
export const addNews = (req) => {
  let NewsDtata = req.data;

  let Newsdata = new FormData();
  if (NewsDtata.type === "news") {
    Newsdata.append("title", NewsDtata.title);
    Newsdata.append("imgUrl", NewsDtata.imgUrl);
    Newsdata.append("shortDesc", NewsDtata.shortDesc);
    Newsdata.append("type", NewsDtata.type);
    Newsdata.append("longDesc", NewsDtata.longDesc);
    Newsdata.append("tags", NewsDtata.tags);
  }

  let EventsData = new FormData();
  if (NewsDtata.type === "events") {
    EventsData.append("title", NewsDtata.title);
    EventsData.append("imgUrl", NewsDtata.imgUrl);
    EventsData.append("shortDesc", NewsDtata.shortDesc);
    EventsData.append("type", NewsDtata.type);
    EventsData.append("place", NewsDtata.place);
    EventsData.append("link", NewsDtata.link);
    EventsData.append("tags", NewsDtata.tags);
    EventsData.append("date", NewsDtata.date);
    EventsData.append("time", NewsDtata.time);
  }
  return axios
    .post(
      `${REACT_APP_API_URL}/NewsEvents/addNews`,
      NewsDtata.type === "news" ? Newsdata : EventsData
      // {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getNews = (req) => {
  return axios
    .get(`${REACT_APP_API_URL}/NewsEvents/getNews`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateNewsService = (req) => {
  let NewsDtata = req.data;

  let Newsdata = new FormData();
  if (NewsDtata.type === "news") {
    Newsdata.append("title", NewsDtata.title);
    Newsdata.append("imgUrl", NewsDtata.imgUrl);
    Newsdata.append("shortDesc", NewsDtata.shortDesc);
    Newsdata.append("type", NewsDtata.type);
    Newsdata.append("longDesc", NewsDtata.longDesc);
    Newsdata.append("tags", NewsDtata.tags);
  }

  let EventsData = new FormData();
  if (NewsDtata.type === "events") {
    EventsData.append("title", NewsDtata.title);
    EventsData.append("imgUrl", NewsDtata.imgUrl);
    EventsData.append("shortDesc", NewsDtata.shortDesc);
    EventsData.append("type", NewsDtata.type);
    EventsData.append("place", NewsDtata.place);
    EventsData.append("link", NewsDtata.link);
    EventsData.append("tags", NewsDtata.tags);
    EventsData.append("date", NewsDtata.date);
    EventsData.append("time", NewsDtata.time);
  }
  return axios
    .post(
      `${REACT_APP_API_URL}/NewsEvents/updateNews?id=${req.data.id}`,
      NewsDtata.type === "news" ? Newsdata : EventsData
      // {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const deleteNewsService = (req) => {
  return axios
    .delete(`${REACT_APP_API_URL}/NewsEvents/deleteNews?id=${req.data}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
