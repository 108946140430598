import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";
export const getAllVacancy = (req) => {
  return axios
    .get(`${REACT_APP_API_URL}/career/getJobVacancy`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addVacancyService = (req) => {
  let jobPosting = req.data;

  let data = new FormData();
  data.append("Jobtitle", jobPosting.Jobtitle);
  data.append("city", jobPosting.city);
  data.append("country", jobPosting.country);
  data.append("shortDescription", jobPosting.shortDescription);
  data.append("JobDesc", jobPosting.JobDesc);
  data.append("link", jobPosting.link);
  data.append("longDesc", jobPosting.longDesc);
  data.append("isActive", jobPosting.isActive);
  return axios
    .post(`${REACT_APP_API_URL}/career/addJobVacancy`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateVacancyService = (req) => {
  let jobPosting = req.data;

  let data = new FormData();
  data.append("Jobtitle", jobPosting.Jobtitle);
  data.append("city", jobPosting.city);
  data.append("country", jobPosting.country);
  data.append("shortDescription", jobPosting.shortDescription);
  data.append("JobDesc", jobPosting.JobDesc);
  data.append("link", jobPosting.link);
  data.append("longDesc", jobPosting.longDesc);
  data.append("isActive", jobPosting.isActive);

  return axios
    .post(`${REACT_APP_API_URL}/career/updateVacancy?id=${req.data.id}`, data)
    .then((response) => {
      return response;
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteVacncyService = (req) => {
  return axios
    .delete(`${REACT_APP_API_URL}/career/deleteVacancy?id=${req.data}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
export const getAllApplication = (req) => {
  return axios
    .get(`${REACT_APP_API_URL}/career/allApplications`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
