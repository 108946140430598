import axios from "axios";
import { REACT_APP_API_URL } from "./index.service";

export const signUp = (request) => {
  return axios
    .post(`${REACT_APP_API_URL}/auth/AdminRegistartion`, request.data)

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const LoginService = (request) => {
  return axios
    .post(`${REACT_APP_API_URL}/auth/login`, request.data, {
      withCredentials: true,
      credentials: "include",
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const authCheckService = () => {
  return axios
    .get(`${REACT_APP_API_URL}/auth/authCheck`, {
      withCredentials: true,
      credentials: "include",
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const updatePassword = (request) => {
  return axios
    .post(`${REACT_APP_API_URL}/auth/updatePassword`, request.data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const logout = (req) => {
  return axios
    .post(`${REACT_APP_API_URL}/auth/logout`, req.data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
