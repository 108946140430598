import { put, call } from "redux-saga/effects";
import {
  addProductCat,
  getProductCat,
  updateProductCatservice,
  deleteProductCatService,
} from "../service/productcat";
import {
  addProductCatSucess,
  getProductCatSuccess,
  updateProductCatSucess,
  deletProductCatSucess,
} from "../action/productcategory";
import * as types from "../action.type";
export function* addProductCatSAGA(data) {
  const response = yield call(addProductCat, data);
  if (response && !response.error) {
    yield put(addProductCatSucess(response.data));
  } else {
    yield put({ type: types.ADD_PRODUCT_CATEGORY_ERROR, response });
  }
}
export function* getProductCatSAGA(data) {
  const response = yield call(getProductCat, data);
  if (response && !response.error) {
    yield put(getProductCatSuccess(response.data));
  } else {
    yield put({ type: types.GET_PRODUCT_CATEGORY_ERROR, response });
  }
}
// update
export function* updateProductCatSaga(data) {
  const response = yield call(updateProductCatservice, data);
  if (response && !response.error) {
    yield put(updateProductCatSucess(response.data));
  } else {
    yield put({ type: types.UPDATE_PRODUCT_CATEGORY_ERROR, response });
  }
}
// dlete
export function* deleteProductCat(data) {
  const response = yield call(deleteProductCatService, data);
  if (response && !response.error) {
    yield put(deletProductCatSucess(response.data));
  } else {
    yield put({ type: types.DELETE_PRODUCT_CATEGORY_ERROR, response });
  }
}
