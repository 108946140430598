import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Cards from "./cards";
import AddVacancyModal from "../allVacancy/AddVacancyModel";
import Filter from "./filter";
import { getAllApplication } from "../../../store/action/careeraction";
import CsvDownloader from "react-csv-downloader";
import PlayForWorkRoundedIcon from "@material-ui/icons/PlayForWorkRounded";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: 48,
    marginLeft: 20,
    flexWrap: "wrap",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
const Contact = () => {
  const dispatch = useDispatch();

  const allApp = useSelector((state) => state.getAllApplication);

  const [open, setopen] = useState(false);
  useEffect(() => {
    dispatch(getAllApplication());
  }, [dispatch]);
  let AllApplication = allApp.jobApplications && allApp.jobApplications;

  const [ApplicationData, setApplicationData] = useState(AllApplication);
  const classes = useStyles();
  // const handleClick = () => {
  //   setopen(true);
  // };
  const handleClose = () => {
    setopen(false);
  };

  const handleFilter_value = (val) => {
    if (val) {
      let filterData =
        allApp &&
        allApp.jobApplications.filter((obj) =>
          val === "All"
            ? obj
            : val === "Others"
            ? obj.others === "others"
            : obj.jobTitle === val.Jobtitle
        );

      setApplicationData(filterData);
    }
  };
  const columns = [
    {
      id: "name",
      displayName: "name",
    },
    {
      id: "email",
      displayName: "email",
    },
    { id: "code", displayName: "countryCode" },
    { id: "phone", displayName: "phone" },
    { id: "Experience", displayName: "Experience" },
    { id: "highestQualification", displayName: "highestQualification" },
    { id: "jobTitle", displayName: "jobTitle" },
    { id: "linkedInProfile", displayName: "linkedInProfile" },
    { id: "resumeUrl", displayName: "resumeUrl" },
    { id: "others", displayName: "others" },
    { id: "User_choice", displayName: "User_choice" },
  ];

  const datas = AllApplication;

  return (
    <Grid md={12} xs={12} sm={12} item>
      <Grid
        md={12}
        xs={12}
        sm={12}
        container
        item
        style={{ margin: 32, display: "flex" }}
      >
        <Grid
          md={9}
          item
          container
          justify="flex-start"
          style={{ marginTop: 9 }}
        >
          <Typography>Job Applications</Typography>
        </Grid>
        <Grid md={3} item style={{ display: "flex" }}>
          <Filter handleFilter_value={(val) => handleFilter_value(val)} />
          <CsvDownloader
            filename="myfile"
            separator=";"
            wrapColumnChar="'"
            columns={columns}
            datas={datas}
            text="DOWNLOAD"
          >
            <Button
              style={{
                color: "#FD6A02",
                fontSize: "16px",
                fontWeight: 700,
                margin: "0px 32px",
                textTransform: "none",
              }}
            >
              <PlayForWorkRoundedIcon />
              Download CSV
            </Button>
          </CsvDownloader>
        </Grid>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        spacing={4}
        className={classes.root}
      >
        {!ApplicationData
          ? AllApplication &&
            AllApplication.map((obj, index) => {
              return (
                <Grid md={6} xs={12} sm={12} item>
                  <Cards data={obj} />
                </Grid>
              );
            })
          : null}

        {AllApplication && AllApplication.length > 0 ? (
          ApplicationData &&
          ApplicationData.map((obj, index) => {
            return (
              <Grid key={index} md={6} xs={12} sm={12} item>
                <Cards data={obj} />
              </Grid>
            );
          })
        ) : (
          <Typography>No Application Found</Typography>
        )}
      </Grid>
      <AddVacancyModal open={open} onClose={handleClose} />
    </Grid>
  );
};
export default Contact;
